import { useAuth, useCharter } from 'hooks';
import { useEffect } from 'react';
import { setUser, setContext } from '@sentry/react';
import formatUserName from 'utils/user/formatUserName';

export default function useSentryContext() {
  const { charter } = useCharter();
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (isAuthenticated && user) {
      setUser({ username: formatUserName(user), email: user.email });
    } else {
      setUser(null);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (charter) {
      setContext('charter', {
        id: charter.id,
        name: charter.name,
      });
    } else {
      setContext('charter', null);
    }
  }, [charter]);
}
