import { LocaleTitles } from './types';

const localeTitles: LocaleTitles = {
  'en-GB': 'English',
  'cs-CZ': 'Čeština',
  'sk-SK': 'Slovenčina',
  'de-DE': 'Deutsch',
  'fr-FR': 'Français',
  'pl-PL': 'Polski',
  'it-IT': 'Italiano',
  'hu-HU': 'Magyar',
  'es-ES': 'Español',
  'ru-RU': 'Русский',
  'hr-HR': 'Hrvatski',
  'el-GR': 'Ελληνικά',
};

export default localeTitles;
