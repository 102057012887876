import { IconProps } from './IconProps';

export default function IconOpenInNew(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      fill="currentColor"
      viewBox="0 0 42 42"
      {...props}
    >
      <path d="M24 0H10v32h32V17.5h-3V29H13V3h11V0z" />
      <path d="M39 9.5V5.132L26.071 18.06 23.95 15.94 36.89 3H32V0h10v9.5h-3zM3 17.5H0V42h24v-3H3V17.5z" />
    </svg>
  );
}
