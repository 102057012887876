import { Dayjs } from 'dayjs';
import { PricesOptions } from 'types/discount';
import { DAY_FORMAT, MONTH_FORMAT } from 'utils/constants/calendar';
import weekdayNameToIndex from 'utils/date/weekdayNameToIndex';

export default function createTimeline(
  start: Dayjs,
  end: Dayjs,
  pricesOptions: PricesOptions
) {
  const { mode, rentalPeriod } = pricesOptions;

  const isPeriodMode = mode === 'period' && !!rentalPeriod;

  let from = start;
  const to = end;

  const days: string[] | null = [];
  const months = new Map<string, number>();
  const periods: string[] | null = [];

  const diff = to.diff(from, 'day');

  for (let i = 0; i <= diff; i++) {
    const startDayFormatted = from.format(DAY_FORMAT);
    const isStartOfPeriod = isPeriodMode
      ? from.weekday() === weekdayNameToIndex(rentalPeriod.start)
      : null;
    const endOfPeriod = from.add((rentalPeriod?.daysCount || 1) - 1, 'day');
    const isEndOfPeriodInDateRange = endOfPeriod.isBefore(to.add(1, 'day'));

    const isValidPeriod =
      isPeriodMode && isStartOfPeriod && isEndOfPeriodInDateRange;

    days.push(startDayFormatted);

    if (isValidPeriod) {
      periods.push(startDayFormatted);
    }

    const monthFormatted = from.format(MONTH_FORMAT);
    // Count number of visible days/weeks in month
    if (mode === 'day' || isValidPeriod) {
      months.set(monthFormatted, (months.get(monthFormatted) || 0) + 1);
    }

    from = from.add(1, 'day');
  }

  return { days, months: Array.from(months), periods };
}
