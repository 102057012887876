import { IconProps } from './IconProps';

export default function IconWhatsApp(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 42"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6219 25.1688C30.0993 24.9085 27.5303 23.6506 27.0514 23.4769C26.5725 23.3033 26.2242 23.2167 25.8758 23.7371C25.5275 24.2577 24.526 25.4291 24.2212 25.7762C23.9165 26.1232 23.6116 26.1668 23.0892 25.9063C22.5666 25.6461 20.8829 25.097 18.8871 23.3252C17.3339 21.9463 16.2851 20.2434 15.9803 19.7228C15.6755 19.2022 15.9478 18.9208 16.2094 18.6615C16.4446 18.4285 16.732 18.0541 16.9932 17.7504C17.2545 17.4469 17.3416 17.2298 17.5157 16.8829C17.6899 16.5358 17.6028 16.2322 17.4721 15.9719C17.3416 15.7116 16.2966 13.1519 15.8612 12.1106C15.437 11.0967 15.0062 11.234 14.6855 11.2179C14.3811 11.2029 14.0323 11.1997 13.684 11.1997C13.3357 11.1997 12.7696 11.3298 12.2906 11.8503C11.8117 12.3709 10.4619 13.6291 10.4619 16.1886C10.4619 18.7484 12.3341 21.221 12.5954 21.5681C12.8567 21.9152 16.2799 27.1677 21.5217 29.4202C22.7683 29.9561 23.7416 30.276 24.5004 30.5156C25.7522 30.9115 26.8912 30.8556 27.7916 30.7217C28.7955 30.5725 30.883 29.4638 31.3185 28.2492C31.7539 27.0342 31.7539 25.9931 31.6233 25.7762C31.4927 25.5592 31.1444 25.4291 30.6219 25.1688ZM21.0879 38.1243H21.0808C17.9618 38.1231 14.9028 37.2891 12.2341 35.7131L11.5994 35.3382L5.02097 37.0556L6.77685 30.6723L6.36366 30.0178C4.62369 27.2637 3.7047 24.0805 3.70609 20.8119C3.70995 11.2745 11.5073 3.51512 21.0947 3.51512C25.7373 3.51667 30.1014 5.31836 33.3831 8.58808C36.6648 11.8577 38.4709 16.2038 38.4691 20.8259C38.4652 30.3641 30.668 38.1243 21.0879 38.1243ZM35.8807 6.10356C31.9325 2.16972 26.6819 0.00228589 21.0876 0C9.56107 0 0.179895 9.33573 0.175219 20.8106C0.173742 24.4788 1.13663 28.059 2.96674 31.2153L0 42L11.0858 39.1059C14.1403 40.764 17.5793 41.6379 21.0793 41.6391H21.0879C32.6132 41.6391 41.9952 32.3024 42 20.8273C42.0021 15.2663 39.8289 10.0374 35.8807 6.10356Z"
        fill="currentColor"
      />
    </svg>
  );
}
