import { useCurrentRoute } from 'hooks';
import { useEffect, useRef } from 'react';

export default function useDocumentTitle(
  suffix: string = '',
  separator: string = ' | '
) {
  const defaultTitle = useRef(document.title);
  const route = useCurrentRoute();

  useEffect(() => {
    let title = defaultTitle.current;

    if (route) {
      title = [route.title, suffix]
        .filter((title) => title !== '')
        .join(separator);
    }

    document.title = title;
  }, [route, suffix, separator]);
}
