import { CharterAllBoatsList } from 'types/fleet';

export default function filterByMarinas(
  boats: CharterAllBoatsList,
  marinas: string[]
) {
  // Multiselect logic - if no marinas are selected, return all boats
  if (!marinas.length) {
    return boats;
  }

  return boats.filter(
    (boat) => boat.marina_id && marinas.includes(boat.marina_id)
  );
}
