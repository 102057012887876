import { IconProps } from './IconProps';

export default function IconDots(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width={41}
      viewBox="0 0 41 40"
      {...props}
    >
      <ellipse cx="20.4" cy="11" rx="2.952" ry="3" />
      <ellipse cx="20.4" cy="20" rx="2.952" ry="3" />
      <ellipse cx="20.4" cy="29" rx="2.952" ry="3" />
    </svg>
  );
}
