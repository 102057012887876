import { QueryKey, useQueryClient } from 'react-query';
import { useCharter, useLocale } from 'hooks';
import { useCallback, useMemo } from 'react';
import { Locale } from 'i18n/types';

type QueryType = 'localized' | 'general';

// Generates cache key by prepending locale (optionally) and charter id
// 1. localized query key format: [locale, charterId, ...otherKeys]
// 2. general query key format: [charterId, ...otherKeys]
// When locale/charter is changed, all queries are invalidated
export default function useReactQuery() {
  const { locale } = useLocale();
  const { charter } = useCharter();
  const queryClient = useQueryClient();

  const charterId = charter?.id || '';

  const createQueryKey = useCallback(
    <
      T extends QueryType,
      R = T extends Extract<QueryType, 'localized'>
        ? [Locale, string, ...unknown[]]
        : [string, ...unknown[]]
    >(
      type: T,
      queryKey: QueryKey
    ): R => {
      if (!Array.isArray(queryKey)) {
        queryKey = [queryKey];
      }

      const queryKeyArray = [charterId, ...queryKey];

      if (type === 'localized') {
        queryKeyArray.unshift(locale);
      }

      return queryKeyArray as unknown as R;
    },
    [charterId, locale]
  );

  const invalidateQuery = useCallback(
    (type: QueryType, queryKey: QueryKey) => {
      queryClient.invalidateQueries(createQueryKey(type, queryKey) as string[]);
    },
    [createQueryKey, queryClient]
  );

  return useMemo(
    () => ({ createQueryKey, invalidateQuery }),
    [createQueryKey, invalidateQuery]
  );
}
