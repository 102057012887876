import { useWindowSize } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'];

function getCurrentBreakpoint() {
  const style = getComputedStyle(document.documentElement);
  return style.getPropertyValue('--current-breakpoint').trim() as Breakpoint;
}

export default function useBreakpoint() {
  const [breakpoint, setBreakpoint] =
    useState<Breakpoint>(getCurrentBreakpoint);

  const { width: windowWidth } = useWindowSize();

  const breakpointIndex = useMemo(
    () => breakpoints.indexOf(breakpoint),
    [breakpoint]
  );

  const isBreakpointTo = useCallback(
    (limit: Breakpoint) => {
      return breakpointIndex <= breakpoints.indexOf(limit);
    },
    [breakpointIndex]
  );

  const isBreakpointFrom = useCallback(
    (limit: Breakpoint) => {
      return breakpointIndex >= breakpoints.indexOf(limit);
    },
    [breakpointIndex]
  );

  const isBreakpointBetween = useCallback(
    (bottomLimit: Breakpoint, topLimit: Breakpoint) => {
      const bottomLimitIndex = breakpoints.indexOf(bottomLimit);
      const topLimitIndex = breakpoints.indexOf(topLimit);

      if (bottomLimitIndex > topLimitIndex) {
        throw Error('Bottom limit cannot be greater than top limit.');
      }

      return (
        breakpointIndex >= bottomLimitIndex && breakpointIndex <= topLimitIndex
      );
    },
    [breakpointIndex]
  );

  useEffect(() => {
    setBreakpoint(getCurrentBreakpoint());
  }, [windowWidth]);

  return useMemo(
    () => ({
      isBreakpointTo,
      isBreakpointFrom,
      isBreakpointBetween,
      breakpoint,
    }),
    [breakpoint, isBreakpointBetween, isBreakpointTo, isBreakpointFrom]
  );
}
