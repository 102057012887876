import { WeekdayIndex, WeekdayName } from 'types';

export const WEEKDAYS: Record<WeekdayName, WeekdayIndex> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const BAREBOAT_ID = '5ad8a7867e055a52e75680ea';
export const CREWED_ID = '5ad8a7867e055a52e75680e9';
export const NO_LICENCE_NEEDED__ID = '5b6c3d81b97532102d4829ca';

// Used for rendering tables and pagination logic
export const ITEMS_PER_PAGE = 20;

export const NOT_AVAILABLE = 'N/A';
