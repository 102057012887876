import { IconProps } from './IconProps';

export default function IconSelected(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fill="#currentColor"
        fillRule="evenodd"
        d="M.238 2.556A2.318 2.318 0 0 1 2.556.238h10.602a2.318 2.318 0 0 1 2.318 2.318v10.602a2.318 2.318 0 0 1-2.318 2.318H2.556a2.318 2.318 0 0 1-2.318-2.318V2.556Z"
        clipRule="evenodd"
      />
      <path
        fill="#currentColor"
        fillRule="evenodd"
        d="M4.524 16.905v.539a2.318 2.318 0 0 0 2.318 2.318h10.601a2.318 2.318 0 0 0 2.319-2.318V6.842a2.318 2.318 0 0 0-2.319-2.318h-.538v1.428h.538a.89.89 0 0 1 .89.89v10.602a.89.89 0 0 1-.89.89H6.842a.89.89 0 0 1-.89-.89v-.54H4.524Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m12.474 4.111-5.93 6.036a.464.464 0 0 1-.706-.05L3.412 6.805l.747-.551 2.104 2.853 5.549-5.647.662.651Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
