import dayjs from 'dayjs';
import { PricesOptions } from 'types/discount';
import createTimeline from './createTimeline';

export default function createDiscountTimeline(
  discountFrom: string | null,
  discountTo: string | null,
  startDayOfTimeline: string,
  endDayOfTimeline: string,
  pricesOptions: PricesOptions
) {
  // Use startDay/endDay of default timeline to create discount timeline if the discount is infinite
  if (!discountFrom && !discountTo) {
    return createTimeline(
      dayjs.utc(startDayOfTimeline),
      dayjs.utc(endDayOfTimeline),
      pricesOptions
    );
  }

  const emptyTimeline = { days: [], months: [], periods: [] };

  // Handle infinite-discountTo
  if (!discountFrom) {
    const isToBeforeStart = dayjs
      .utc(discountTo)
      .isBefore(startDayOfTimeline, 'day');

    if (isToBeforeStart) {
      return emptyTimeline;
    }

    const isToAfterEnd = dayjs.utc(discountTo).isAfter(endDayOfTimeline, 'day');

    return createTimeline(
      dayjs.utc(startDayOfTimeline),
      dayjs.utc(isToAfterEnd ? endDayOfTimeline : discountTo),
      pricesOptions
    );
  }

  const isFromBeforeStart = dayjs
    .utc(discountFrom)
    .isBefore(startDayOfTimeline, 'day');

  // Handle discountFrom-infinite
  if (!discountTo) {
    const isFromAfterEnd = dayjs
      .utc(discountFrom)
      .isAfter(endDayOfTimeline, 'day');

    if (isFromAfterEnd) {
      return emptyTimeline;
    }

    return createTimeline(
      dayjs.utc(isFromBeforeStart ? startDayOfTimeline : discountFrom),
      dayjs.utc(endDayOfTimeline),
      pricesOptions
    );
  }

  const isToAfterEnd = dayjs.utc(discountTo).isAfter(endDayOfTimeline, 'day');

  // Use from/to to create discount timeline if they both fit into the timeline
  return createTimeline(
    dayjs.utc(isFromBeforeStart ? startDayOfTimeline : discountFrom),
    dayjs.utc(isToAfterEnd ? endDayOfTimeline : discountTo),
    pricesOptions
  );
}
