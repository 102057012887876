import { useEffect, useMemo, useState } from 'react';
import { Timeline } from 'types/calendar';
import {
  PricesOptions,
  PricesList,
  PricesWithDiscountsGroupedByBoat,
  DiscountsList,
} from 'types/discount';
import { CharterActiveBoatsList } from 'types/fleet';
import processPricesWithDiscounts from 'utils/discount/processPricesWithDiscounts';

export default function useDiscountsPagination(
  timeline: Timeline,
  pricesOptions: PricesOptions,
  displayedBoatsCount: number,
  filteredBoats?: CharterActiveBoatsList,
  filteredValidDiscounts?: DiscountsList,
  prices?: PricesList
) {
  const [paginatedData, setPaginatedData] =
    useState<PricesWithDiscountsGroupedByBoat | null>(null);

  // Reset paginated data when filters are used
  useEffect(() => {
    setPaginatedData(filteredBoats ? {} : null);
  }, [filteredBoats]);

  // Process new dataset and append it to paginated data when the page is incremented (table is scrolled down)
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (!filteredBoats || !filteredValidDiscounts || !prices) {
      setPaginatedData(null);
      return;
    }

    const actualNumberOfRows = Object.keys(paginatedData || {}).length;
    const newNumberOfRows = displayedBoatsCount;
    const shouldIncrementPage = actualNumberOfRows < newNumberOfRows;
    const hasNextPage = actualNumberOfRows < filteredBoats.length;

    if (shouldIncrementPage && hasNextPage) {
      // Set timeout to let the checkbox filters animations finish before executing data processing
      timeout = setTimeout(() => {
        const newData = processPricesWithDiscounts(
          timeline,
          pricesOptions,
          prices,
          filteredBoats,
          filteredValidDiscounts,
          actualNumberOfRows,
          newNumberOfRows
        );

        setPaginatedData((prevData) => ({
          ...prevData,
          ...newData,
        }));
      }, 100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    displayedBoatsCount,
    filteredBoats,
    filteredValidDiscounts,
    paginatedData,
    prices,
    pricesOptions,
    timeline,
  ]);

  const paginatedBoats = useMemo(() => {
    if (!paginatedData || !filteredBoats) {
      return null;
    }

    return filteredBoats.slice(0, Object.keys(paginatedData).length);
  }, [filteredBoats, paginatedData]);

  return useMemo(
    () => ({
      paginatedData,
      paginatedBoats,
    }),
    [paginatedData, paginatedBoats]
  );
}
