import { useMemo, useReducer } from 'react';
import {
  discountsFiltersReducer,
  initialDiscountsFilters,
} from 'reducers/filters/discounts';
import { DiscountsList } from 'types/discount';
import { CharterActiveBoatsList } from 'types/fleet';
import {
  DISCOUNT_AMOUNT_MIN_VALUE,
  DISCOUNT_NAME,
} from 'utils/constants/filters';
import { filterBoats, filterDiscounts } from 'utils/filters';

export default function useDiscountsFilters(
  validDiscounts?: DiscountsList,
  boats?: CharterActiveBoatsList
) {
  const [filters, dispatchFilters] = useReducer(
    discountsFiltersReducer,
    initialDiscountsFilters
  );

  const filteredValidDiscounts = useMemo(
    () =>
      validDiscounts
        ? filterDiscounts(validDiscounts, {
            name: filters.discountName,
            amount: filters.discountAmount,
          })
        : undefined,
    [validDiscounts, filters.discountAmount, filters.discountName]
  );

  const boatIdsWithoutValidDiscount = useMemo(() => {
    if (!boats) {
      return [];
    }

    const ids: string[] = [...boats.map(({ id }) => id)];

    validDiscounts?.forEach((discount) => {
      discount.boats.forEach((boatId) => {
        const indexOfBoatId = ids.indexOf(boatId);
        if (indexOfBoatId > -1) {
          ids.splice(indexOfBoatId, 1);
        }
      });
    });

    return ids;
  }, [boats, validDiscounts]);

  const filteredBoats = useMemo(() => {
    if (!boats) {
      return undefined;
    }

    /* If discount name filter is not used and min value of amount filter is 0,
       boats without discount should be displayed as well (they are considered as boats with zero discount) */
    const discountNameFilterNotUsed = filters.discountName === DISCOUNT_NAME;
    const discountAmountFilterNotUsed =
      filters.discountAmount.min === DISCOUNT_AMOUNT_MIN_VALUE;

    const filteredBoatIdsByDiscounts = [
      ...new Set(filteredValidDiscounts?.flatMap((discount) => discount.boats)),
    ];

    return filterBoats(boats, {
      title: filters.boatTitle,
      ids:
        discountNameFilterNotUsed && discountAmountFilterNotUsed
          ? [...filteredBoatIdsByDiscounts, ...boatIdsWithoutValidDiscount]
          : filteredBoatIdsByDiscounts,
    }) as CharterActiveBoatsList;
  }, [
    boatIdsWithoutValidDiscount,
    boats,
    filteredValidDiscounts,
    filters.boatTitle,
    filters.discountAmount.min,
    filters.discountName,
  ]);

  return useMemo(
    () => ({
      filteredBoats,
      filteredValidDiscounts,
      filters,
      changeFilters: dispatchFilters,
    }),
    [filteredBoats, filteredValidDiscounts, filters]
  );
}
