import axios from 'axios';

export type AccountManager = {
  email: string;
  name: string;
  phone: string;
};

export default async function fetchAccountManagerByCharterId(
  charterId: string
) {
  const { data } = await axios.get<AccountManager>(
    `/charters/${charterId}/account-manager`
  );

  return data;
}
