import { IconProps } from './IconProps';

export default function IconArrowRight(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 42"
      width={43}
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M42.085 19.964L25.5 2.487l-2.17 2.071L37.495 19.5H.5v3h36.995L23.33 37.441l2.17 2.072 16.585-17.477a1.5 1.5 0 000-2.072z"
        clipRule="evenodd"
      />
    </svg>
  );
}
