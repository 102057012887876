import fetchOrdersCountByCharterId from 'api/order/fetchOrdersCountByCharterId';
import { useReactQuery } from 'hooks';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ORDERS_PENDING_COUNT } from 'utils/constants/queryKeys';

export default function useSidebarCount() {
  const { createQueryKey } = useReactQuery();

  const queryKey = createQueryKey('general', ORDERS_PENDING_COUNT);
  const { data: ordersCountData } = useQuery(
    queryKey,
    () => fetchOrdersCountByCharterId(queryKey[0], 'PENDING'),
    { refetchInterval: 15_000 }
  );

  return useMemo(
    () => ({
      ordersCount: ordersCountData?.count || 0,
    }),
    [ordersCountData?.count]
  );
}
