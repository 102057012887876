import { MinMaxNumber } from 'types';
import { DiscountsList } from 'types/discount';

export default function filterByAmount(
  discounts: DiscountsList,
  amount: MinMaxNumber
) {
  return discounts.filter(
    (discount) => discount.amount >= amount.min && discount.amount <= amount.max
  );
}
