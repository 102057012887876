import dayjs from 'dayjs';
import { WeekdayIndex, WeekdayName } from 'types';
import { WEEKDAYS } from 'utils/constants';

const NUMBER_OF_WEEKDAYS = 7;

// Calculates correct index of weekday according to actual locale
export default function weekdayNameToIndex(weekdayName: WeekdayName) {
  const indexOfFirstDayOfWeek = dayjs.localeData().firstDayOfWeek();
  const weekdayIndex = WEEKDAYS[weekdayName] - indexOfFirstDayOfWeek;

  return (
    weekdayIndex < 0 ? weekdayIndex + NUMBER_OF_WEEKDAYS : weekdayIndex
  ) as WeekdayIndex;
}
