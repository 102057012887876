import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';

export type BadgeProps = {
  color?: 'blue' | 'red';
  size?: 'small' | 'normal' | 'large';
  hidden?: boolean;
  showZero?: boolean; // force badge to show when value is 0
  max?: 9 | 99 | 999;
  value?: number;
  children: ReactNode;
  className?: string;
};

export default function Badge({
  color = 'blue',
  size = 'normal',
  hidden,
  showZero,
  max = 99,
  value,
  children,
  className,
}: BadgeProps) {
  const sanitizedValue = useMemo(() => {
    if (value === 0 && showZero) {
      return '0';
    }

    if (!value) {
      return '';
    }

    if (value > max) {
      return max + '+';
    }

    return value.toString();
  }, [max, value, showZero]);

  return (
    <div className="relative inline align-middle">
      <div className="inline">{children}</div>
      {((!!value && value > 0) ||
        (value === 0 && showZero) ||
        value === undefined) && (
        <span
          data-testid="badge"
          className={classNames(
            'absolute px-[6px] z-1 rounded-full text-center text-white font-normal',
            {
              'bg-blue-500': color === 'blue',
              'bg-red-500': color === 'red',
              'h-[16px] min-w-[16px] text-xs': value && size === 'small',
              'h-[20px] min-w-[20px] text-sm': value && size === 'normal',
              'h-[24px] min-w-[24px] text-base': value && size === 'large',
              'w-[6px] h-[6px] min-w-0':
                value === undefined && size === 'small',
              'w-4 h-4 min-w-0': value === undefined && size === 'normal',
              'w-5 h-5 min-w-0': value === undefined && size === 'large',
              'hidden': hidden,
              'px-0': sanitizedValue.length < 2,
            },
            className
          )}
        >
          {sanitizedValue}
        </span>
      )}
    </div>
  );
}
