import { IconProps } from './IconProps';

export default function IconGlobe(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      fill="currentColor"
      viewBox="0 0 42 42"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21zm7.528-4.645a18.06 18.06 0 009.36-10.113c-1.632 1.102-3.685 2.039-6.04 2.75-.78 2.874-1.92 5.388-3.32 7.363zM13.472 4.645a18.06 18.06 0 00-9.36 10.113c1.632-1.102 3.685-2.039 6.04-2.75.78-2.874 1.92-5.388 3.32-7.363zm.07 6.537c.449-1.311.982-2.5 1.578-3.543 1.403-2.457 2.954-3.803 4.38-4.348v7.235c-2.085.074-4.086.3-5.959.655zm5.958 2.346c-2.468.094-4.777.416-6.845.91a31.785 31.785 0 00-.622 5.062H19.5v-5.972zm3 5.972v-5.972c2.468.094 4.777.416 6.845.91.328 1.582.544 3.28.622 5.062H22.5zm-3 3h-7.467c.078 1.782.294 3.48.622 5.062 2.068.494 4.377.816 6.845.91V22.5zm3 5.972V22.5h7.467a31.796 31.796 0 01-.622 5.062c-2.068.494-4.377.816-6.845.91zm0 3.002c2.085-.074 4.086-.3 5.959-.655-.45 1.31-.983 2.5-1.578 3.542-1.404 2.457-2.955 3.803-4.381 4.348v-7.235zm-3 0v7.235c-1.426-.545-2.977-1.891-4.38-4.348a20.671 20.671 0 01-1.579-3.542c1.873.355 3.874.581 5.959.655zm3-20.948V3.291c1.426.545 2.977 1.891 4.38 4.348a20.68 20.68 0 011.579 3.542 39.463 39.463 0 00-5.959-.655zM9.429 15.418c-.69.26-1.338.541-1.937.84-2.21 1.106-3.425 2.256-4.017 3.242H9.03a35.54 35.54 0 01.399-4.082zM7.492 25.74c-2.21-1.105-3.425-2.255-4.017-3.241H9.03a35.54 35.54 0 00.399 4.082 20.48 20.48 0 01-1.937-.84zm25.08.841a35.54 35.54 0 00.398-4.082h5.555c-.592.986-1.806 2.136-4.017 3.241-.6.3-1.246.581-1.937.841zm.398-7.082h5.555c-.592-.986-1.806-2.136-4.017-3.241-.6-.3-1.246-.581-1.937-.841a35.54 35.54 0 01.399 4.082zM10.153 29.993c.779 2.873 1.92 5.387 3.32 7.362a18.06 18.06 0 01-9.361-10.113c1.632 1.102 3.685 2.039 6.04 2.75zm21.694-17.986c2.356.712 4.41 1.65 6.041 2.751a18.06 18.06 0 00-9.36-10.113c1.4 1.975 2.54 4.49 3.32 7.362z"
        clipRule="evenodd"
      />
    </svg>
  );
}
