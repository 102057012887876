import { IconProps } from './IconProps';

export default function IconChevronDown(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      viewBox="0 0 42 42"
      fill="currentColor"
      {...props}
    >
      <path d="M22 29.5l15.38-15.379L35.26 12 20.94 26.319 6.621 12 4.5 14.121l15.38 15.38a1.5 1.5 0 002.12 0z" />
    </svg>
  );
}
