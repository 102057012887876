import classNames from 'classnames';
import { ReactNode } from 'react';

export type LabelProps = {
  blue?: boolean;
  gray?: boolean;
  green?: boolean;
  orange?: boolean;
  wrap?: boolean;
  children: ReactNode;
};

export default function Label({
  blue,
  gray,
  green,
  orange,
  wrap,
  children,
}: LabelProps) {
  return (
    <span
      className={classNames(
        'inline-block rounded-full px-4 text-center py-2 text-xs uppercase font-normal',
        {
          'text-blue-600 bg-blue-50': blue,
          'text-gray-600 bg-gray-100': gray,
          'text-green-600 bg-green-50': green,
          'text-orange-brick-600 bg-orange-brick-50': orange,
          'whitespace-nowrap': !wrap,
          'max-w-min': wrap,
        }
      )}
    >
      {children}
    </span>
  );
}
