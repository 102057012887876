import { useLocale, useCharter } from 'hooks';
import generateRoute from 'utils/url/generateRoute';

function useRoute(path: string, params = {}) {
  const { locale } = useLocale();
  const { charter } = useCharter();

  return generateRoute(path, locale, {
    charter: charter?.id,
    ...params,
  });
}

export default useRoute;
