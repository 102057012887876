import { IconProps } from './IconProps';

export default function IconSignOut(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      fill="currentColor"
      viewBox="0 0 43 42"
      {...props}
    >
      <path d="M21.5 19.5a9 9 0 110-18 9 9 0 010 18zm0-3a6 6 0 100-12 6 6 0 000 12zM30.44 27l7.06 4.94a1.5 1.5 0 010 2.122L30.44 39v-4.5h-8.5v-3h8.5V27zM22.055 22.503c-.147-.003-.328-.003-.555-.003C12.387 22.5 5 29.887 5 39h3c0-7.226 5.207-13.125 12.315-13.482l1.74-3.015z" />
    </svg>
  );
}
