import { IconProps } from './IconProps';

export default function IconAttachment(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M9.13893 3.51292L2.62347 10.0284L1.71433 9.11925L8.2298 2.60378C9.98715 0.846428 12.8364 0.846428 14.5937 2.60378C16.3511 4.36114 16.3511 7.21037 14.5937 8.96772L7.47218 16.0893C6.21693 17.3445 4.18176 17.3445 2.92651 16.0893C1.67125 14.834 1.67125 12.7989 2.92651 11.5436L9.13893 5.33119C9.89208 4.57804 11.1132 4.57804 11.8663 5.33119C12.6195 6.08435 12.6195 7.30545 11.8663 8.0586L6.26001 13.6649L5.35087 12.7558L10.9572 7.14946C11.2083 6.89841 11.2083 6.49138 10.9572 6.24033C10.7062 5.98928 10.2991 5.98928 10.0481 6.24033L3.83564 12.4528C3.08249 13.2059 3.08249 14.427 3.83564 15.1802C4.58879 15.9333 5.80989 15.9333 6.56305 15.1802L13.6846 8.05859C14.9399 6.80334 14.9399 4.76817 13.6846 3.51292C12.4293 2.25766 10.3942 2.25766 9.13893 3.51292Z"
        fill="currentColor"
      />
    </svg>
  );
}
