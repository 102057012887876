import MainContainerRefContext from 'contexts/MainContainerRefContext';
import { useContext } from 'react';

const useMainContainerRefContext = () => {
  const context = useContext(MainContainerRefContext);

  if (context === undefined) {
    throw Error(
      'useMainContainerRefContext must be used within a MainContainerRefProvider'
    );
  }

  return context;
};

export default useMainContainerRefContext;
