import { IconProps } from './IconProps';

export default function IconClock(props: IconProps) {
  return (
    <svg
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 12.5703L20.9999 22.4304L25.5 16.5703"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="20.9999"
        cy="21.0703"
        r="19.4999"
        stroke="currentColor"
        strokeWidth="3"
      />
      <circle cx="21" cy="35.0703" r="2.00003" fill="currentColor" />
      <circle cx="35" cy="21.5305" r="1.99995" fill="currentColor" />
      <circle cx="7.00002" cy="21.5303" r="2.00002" fill="currentColor" />
      <circle cx="21" cy="7.07025" r="1.99994" fill="currentColor" />
    </svg>
  );
}
