export default function createURLSearchParams(
  params: Record<string, string | undefined>
) {
  const nonEmptyParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== '' && value !== undefined) {
      nonEmptyParams.append(key, value);
    }
  });

  return nonEmptyParams.toString();
}
