import { IconProps } from './IconProps';

export default function IconInfo(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      viewBox="0 0 18 18"
      fill="currentColor"
      {...props}
    >
      <path d="M6.21428 9V7.71429H9.72292L9.21428 13.2861H10.9286V14.5718H7.5L7.92857 9H6.21428Z" />
      <path d="M9 6.42857C9.71008 6.42857 10.2857 5.85294 10.2857 5.14286C10.2857 4.43278 9.71008 3.85714 9 3.85714C8.28992 3.85714 7.71429 4.43278 7.71429 5.14286C7.71429 5.85294 8.28992 6.42857 9 6.42857Z" />
      <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.7143C4.73952 16.7143 1.28571 13.2605 1.28571 9C1.28571 4.73952 4.73952 1.28571 9 1.28571C13.2605 1.28571 16.7143 4.73952 16.7143 9C16.7143 13.2605 13.2605 16.7143 9 16.7143Z" />
    </svg>
  );
}
