import { IconProps } from './IconProps';

export default function IconCalendar(props: IconProps) {
  return (
    <svg
      width={43}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.714 6.857V4.571H3.43v2.286h2.285ZM6.857 4.571h2.286v2.286H6.857V4.571ZM12.571 6.857V4.571h-2.285v2.286h2.285ZM5.714 8v2.286H3.43V8h2.285ZM9.143 8H6.857v2.286h2.286V8ZM12.571 8v2.286h-2.285V8h2.285ZM5.714 13.714V11.43H3.43v2.285h2.285ZM6.857 11.429h2.286v2.285H6.857V11.43Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.429 0v2.857L1.772 1.201A2.287 2.287 0 0 0 0 3.429v10.285A2.286 2.286 0 0 0 2.286 16h11.428A2.286 2.286 0 0 0 16 13.714V3.43A2.287 2.287 0 0 0 14.228 1.2L12.57 2.857V0H11.43v2.857L9.714 1.143H6.286L4.57 2.857V0H3.43ZM1.143 4.571c0-.63.512-1.142 1.143-1.142h11.428c.631 0 1.143.511 1.143 1.142v9.143c0 .631-.511 1.143-1.143 1.143H2.286a1.143 1.143 0 0 1-1.143-1.143V4.571Z"
      />
    </svg>
  );
}
