import { ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { PrivateRoute } from 'components/routing';
import routesFactory from 'routes';

const wrapByProviders = (
  Component: JSX.Element,
  providers: ComponentType[]
) => {
  return providers
    .reverse()
    .reduce((children, Provider) => <Provider>{children}</Provider>, Component);
};

export default function useRoutes() {
  const { t } = useTranslation();

  return useMemo(() => {
    return routesFactory(t).map(
      ({ contextProviders: providers, ...route }, index) => {
        let Component = <route.component />;

        if (providers) {
          Component = wrapByProviders(Component, providers);
        }

        const RouteComponent = route.private ? PrivateRoute : Route;
        return (
          <RouteComponent
            key={index}
            exact={route.exact}
            path={route.path}
            render={() => Component}
          />
        );
      }
    );
  }, [t]);
}
