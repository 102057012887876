import { IconProps } from './IconProps';

export default function IconPlus(props: IconProps) {
  return (
    <svg
      width={42}
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 42"
    >
      <path d="M20 22.5V36h3V22.5h13.5v-3H23V6h-3v13.5H6.5v3H20z" />
    </svg>
  );
}
