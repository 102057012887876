import { Option } from 'components/Select/Select';
import { CharterAllBoatsList } from 'types/fleet';
import {
  filterByTitle,
  filterByMarinas,
  filterByCabins,
  filterByIds,
} from 'utils/filters/boats';

type Filters = {
  title?: string;
  marinas?: Option[];
  cabins?: [number, number];
  ids?: string[];
};

export default function filterBoats(
  boats: CharterAllBoatsList,
  { title, marinas, cabins, ids }: Filters
) {
  if (title) {
    boats = filterByTitle(boats, title);
  }
  if (marinas) {
    boats = filterByMarinas(
      boats,
      marinas.map((marina) => marina.value)
    );
  }
  if (cabins) {
    boats = filterByCabins(boats, cabins);
  }
  if (ids) {
    boats = filterByIds(boats, ids);
  }

  return boats;
}
