import { IconProps } from './IconProps';

export default function IconChat(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      fill="currentColor"
      viewBox="0 0 43 43"
      {...props}
    >
      <path d="M25.422 34.193c-9.389 0-17-7.611-17-17s7.611-17 17-17 17 7.611 17 17c0 3.096-.828 6-2.274 8.5l2.274 8.5-8.5-2.274a16.921 16.921 0 01-8.5 2.274zm-5-17a2 2 0 10-4 0 2 2 0 004 0zm5 2a2 2 0 100-4 2 2 0 000 4zm9-2a2 2 0 10-4 0 2 2 0 004 0z" />
      <path
        fillRule="evenodd"
        d="M25.422 38.193c-11.598 0-21-9.402-21-21 0-1.103.086-2.186.25-3.244a16.935 16.935 0 00-4.25 11.244c0 3.096.828 6 2.275 8.5l-2.274 8.5 8.5-2.275a16.922 16.922 0 008.5 2.275c4.31 0 8.246-1.605 11.243-4.25a21.16 21.16 0 01-3.244.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}
