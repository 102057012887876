import { IconProps } from './IconProps';

export default function IconSwitch(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42 42"
      fill="currentColor"
      {...props}
    >
      <path d="M42 13.5 32 6v15l10-7.5Z" />
      <path fillRule="evenodd" d="M32 15H5v-3h27v3Z" clipRule="evenodd" />
      <path d="M0 28.5 10 21v15L0 28.5Z" />
      <path fillRule="evenodd" d="M10 30h27v-3H10v3Z" clipRule="evenodd" />
    </svg>
  );
}
