import axios from 'axios';
import { BOATAROUND_WEB_URL } from 'utils/constants/environment';

export default function logout() {
  return axios.post(
    '/account/logout',
    {},
    {
      withCredentials: true,
      baseURL: BOATAROUND_WEB_URL,
    }
  );
}
