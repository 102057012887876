import { isObjectId } from 'utils/validation';

export default function extractCharterIdFromPath(path: string): string | null {
  // charter id is part after locale -> /en/{CHARTER_ID}/route
  const segments = path.split('/');
  if (segments.length < 3) {
    return null;
  }

  const id = segments[2];
  return isObjectId(id) ? id : null;
}
