import { IconProps } from './IconProps';

export default function IconBoat(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      fill="currentColor"
      viewBox="0 0 32 38"
      {...props}
    >
      <path d="M17.4285 0.428528V7.57138H23.1428V10.4285H25.9999V19.9523L23.1428 18.365V13.2857H8.85707V18.365L5.99993 19.9523V10.4285H8.85707V7.57138H14.5714V0.428528H17.4285Z" />
      <path d="M31.7142 32.7901C30.0903 32.2049 28.3239 32.0285 26.7133 32.0973C24.5212 32.191 22.3616 32.7502 20.951 33.6163C19.1267 34.7363 15.3485 35.5352 11.7663 33.5772C9.5622 32.3725 7.73065 31.7841 5.81285 31.8643C3.96006 31.9418 2.23068 32.6442 0.285645 33.5247L1.62772 36.0397C3.60079 35.1466 4.80706 34.7117 5.95086 34.6638C7.02964 34.6187 8.25563 34.9171 10.2134 35.9872C14.955 38.5788 19.9976 37.5697 22.6387 35.9481C23.4607 35.4434 25.036 34.9744 26.8544 34.8967C28.2989 34.835 29.7326 35.0275 30.9194 35.5221L31.7142 32.7901Z" />
      <path d="M4.05214 29.261C4.6338 29.1217 5.21694 29.0321 5.81285 29.0072C7.73065 28.927 9.5622 29.5154 11.7663 30.7201C12.9437 31.3636 14.1422 31.7093 15.2856 31.8437V17.9682L3.14279 24.7142L4.05214 29.261Z" />
      <path d="M17.4693 31.8571H16.7142V17.9682L28.8571 24.7142L27.9524 29.2373C27.5327 29.2211 27.1181 29.2229 26.7133 29.2402C24.5212 29.3339 22.3616 29.8931 20.951 30.7592C20.1247 31.2665 18.8975 31.7079 17.4693 31.8571Z" />
    </svg>
  );
}
