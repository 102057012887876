import { IconProps } from './IconProps';

export default function IconSelectedEmpty(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.238037 2.55639C0.238037 1.27603 1.27597 0.238098 2.55633 0.238098H13.1578C14.4382 0.238098 15.4761 1.27603 15.4761 2.55639V13.1579C15.4761 14.4383 14.4382 15.4762 13.1578 15.4762H2.55633C1.27597 15.4762 0.238037 14.4383 0.238037 13.1579V2.55639ZM2.55633 1.66667C2.06495 1.66667 1.66661 2.06501 1.66661 2.55639V13.1579C1.66661 13.6493 2.06495 14.0476 2.55633 14.0476H13.1578C13.6492 14.0476 14.0476 13.6493 14.0476 13.1579V2.55639C14.0476 2.06501 13.6492 1.66667 13.1578 1.66667H2.55633Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52375 16.9048V17.4436C4.52375 18.724 5.56169 19.7619 6.84205 19.7619H17.4436C18.7239 19.7619 19.7618 18.724 19.7618 17.4436V6.84211C19.7618 5.56175 18.7239 4.52381 17.4436 4.52381H16.9047V5.95238H17.4436C17.9349 5.95238 18.3333 6.35073 18.3333 6.84211V17.4436C18.3333 17.935 17.9349 18.3333 17.4436 18.3333H6.84205C6.35067 18.3333 5.95232 17.935 5.95232 17.4436V16.9048H4.52375Z"
      />
    </svg>
  );
}
