import { useCallback, useMemo } from 'react';
import { GtagEventName } from 'types';

export default function useEventTracking(
  trackingId: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID
) {
  const trackEvent = useCallback(
    (event: GtagEventName) => {
      if (!window.gtag || !trackingId) {
        return;
      }

      gtag('event', event);
    },
    [trackingId]
  );

  return useMemo(() => ({ trackEvent }), [trackEvent]);
}
