import { IconProps } from './IconProps';

export default function IconTerms(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      fill="currentColor"
      viewBox="0 0 41 43"
      {...props}
    >
      <path d="M28.967 27.71h2.896v4.345h-2.896V27.71zM28.967 33.503V36.4h2.896v-2.897h-2.896z" />
      <path d="M1.45 3.09c0-1.6 1.296-2.897 2.896-2.897h24.62c1.6 0 2.897 1.296 2.897 2.896v18.93c4.913.703 8.69 4.928 8.69 10.036 0 5.599-4.539 10.138-10.138 10.138-3.003 0-5.7-1.306-7.556-3.38H4.345a2.897 2.897 0 01-2.897-2.896V3.089zm28.965 21.723a7.226 7.226 0 00-5.552 2.592 7.218 7.218 0 00-1.69 4.65 7.244 7.244 0 004.637 6.758 7.241 7.241 0 102.605-14zm-8.32 1.449H8.448v2.896h12.248a10.092 10.092 0 011.397-2.896zM8.448 12.744h16.414V9.848H8.45v2.896zm0 8.207h16.414v-2.896H8.45v2.896z" />
    </svg>
  );
}
