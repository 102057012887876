import { createContext, ReactNode, useState } from 'react';

type ContextType = {
  localeSettingsShown: boolean;
  toggleLocaleSettingsModal(): void;
};

type GlobalContextProviderProps = {
  children?: ReactNode;
};

const GlobalContext = createContext<ContextType>({
  localeSettingsShown: false,
  toggleLocaleSettingsModal: () => {},
});

function GlobalContextProvider({ children }: GlobalContextProviderProps) {
  const [localeSettingsShown, setLocaleSettingsShown] = useState(false);

  const value: ContextType = {
    localeSettingsShown,
    toggleLocaleSettingsModal: () => {
      setLocaleSettingsShown(!localeSettingsShown);
    },
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export { GlobalContext, GlobalContextProvider };
