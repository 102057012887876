import useUrlSearchParams from 'hooks/useUrlSearchParams/useUrlSearchParams';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const STEP_PARAM = 'step';

export default function useCreateBoatFormStepper() {
  const { searchParams, setSearchParam } = useUrlSearchParams();
  const urlParams = useParams<{ draftId?: string }>();
  const location = useLocation();

  const extractStepFromUrl = useCallback(
    () => Math.abs(Number(searchParams.get(STEP_PARAM))) || 1,
    [searchParams]
  );

  const [step, setStepState] = useState(extractStepFromUrl);
  const [draftId, setDraftId] = useState(urlParams.draftId);

  const setStep = useCallback(
    (step: number) => {
      setStepState(step);

      setSearchParam(STEP_PARAM, step.toString());
    },
    [setSearchParam]
  );

  // sync step with URL param
  useEffect(() => {
    const step = extractStepFromUrl();

    // redirect to 1st step if draftId is undefined in later steps, because we need it to update draft or create boat
    if (step > 1 && !draftId) {
      setStep(1);
      return;
    }

    setStepState(step);
  }, [location, extractStepFromUrl, setStep, draftId]);

  const prevStep = useCallback(() => {
    setStep(step - 1);
  }, [setStep, step]);

  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [setStep, step]);

  const value = useMemo(
    () => ({
      step,
      prevStep,
      nextStep,
      draftId,
      setDraftId: (draftId: string) => setDraftId(draftId),
    }),
    [step, nextStep, prevStep, draftId, setDraftId]
  );

  return value;
}
