import axios from 'axios';
import { BOATAROUND_WEB_URL } from 'utils/constants/environment';

type LoginRequest = {
  email: string;
  password: string;
  remember: boolean;
};

type AuthResponse = {
  data: {
    accessToken: string;
  };
};

export default function login(
  email: string,
  password: string,
  remember: boolean = true
) {
  return axios.post<LoginRequest, AuthResponse>(
    '/account/login-jwt',
    { email, password, remember },
    {
      withCredentials: true,
      baseURL: BOATAROUND_WEB_URL,
    }
  );
}
