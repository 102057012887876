import { useWindowSize } from 'hooks';
import { useLayoutEffect, useState } from 'react';
import setGlobalStylesVariable from 'utils/setGlobalStylesVariable';

export default function useViewportHeightUnit() {
  const [viewportHeight, setViewportHeight] = useState(0);
  const { height } = useWindowSize();

  useLayoutEffect(() => {
    // Fix mobile viewport height unit
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const vh = +(window.innerHeight * 0.01).toFixed(2);
    setGlobalStylesVariable('vh', `${vh}px`);

    setViewportHeight(vh);
  }, [height]);

  return viewportHeight;
}
