import { IconProps } from './IconProps';

export default function IconFreeCancel(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      viewBox="0 0 18 18"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1429 10.2857C14.1429 13.126 11.8403 15.4286 9 15.4286C6.15968 15.4286 3.85714 13.126 3.85714 10.2857C3.85714 7.44539 6.15968 5.14286 9 5.14286C11.8403 5.14286 14.1429 7.44539 14.1429 10.2857ZM12.3474 9.13315L11.4383 8.22401L8.35714 11.3052L6.88314 9.83115L5.974 10.7403L7.90257 12.6689C8.15362 12.9199 8.56066 12.9199 8.81171 12.6689L12.3474 9.13315Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.85714 3.21429V0H5.14286V3.21429L7.07143 1.28571H10.9286L12.8571 3.21429V0H14.1429V3.21429L16.0063 1.35087C17.1483 1.61302 18 2.63562 18 3.85714V15.4286C18 16.8487 16.8487 18 15.4286 18H2.57143C1.15127 18 0 16.8487 0 15.4286V3.85714C0 2.63562 0.851745 1.61302 1.99372 1.35087L3.85714 3.21429ZM2.57143 3.85714C1.86135 3.85714 1.28571 4.43278 1.28571 5.14286V15.4286C1.28571 16.1387 1.86135 16.7143 2.57143 16.7143H15.4286C16.1387 16.7143 16.7143 16.1386 16.7143 15.4286V5.14286C16.7143 4.43278 16.1387 3.85714 15.4286 3.85714H2.57143Z"
      />
    </svg>
  );
}
