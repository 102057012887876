import { FleetFormLabelsContext } from 'contexts/FleetFormLabelsContext';
import { useContext } from 'react';

const useFleetFormLabelsContext = () => {
  const context = useContext(FleetFormLabelsContext);

  if (context === undefined) {
    throw Error(
      'useFleetFormLabelsContext must be used within a FleetFormLabelsProvider'
    );
  }

  return context;
};

export default useFleetFormLabelsContext;
