import { useTranslation } from 'react-i18next';
import Dropdown from 'components/Dropdown/Dropdown';
import {
  IconAccountCircled,
  IconChat,
  // IconDescription,
  IconGlobe,
  IconSignOut,
  IconTerms,
} from 'components/icons';
import { NavLink } from 'components/routing';
import { useContext, useState } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { useAuth, useLocale } from 'hooks';
import { languageCode } from 'utils/i18n';
import { ROUTE_CONTRACTS } from 'utils/constants/routes';
import formatUserName from 'utils/user/formatUserName';
import styles from './SecondaryNavList.module.scss';
import classNames from 'classnames';
import ContactUsModal from 'components/modals/ContactUsModal/ContactUsModal';
import PWAInstallSidebarButton from 'components/pwa/PWAInstallSidebarButton/PWAInstallSidebarButton';

export default function SecondaryNavList() {
  const { t } = useTranslation();

  const [contactUsModalOpened, setContactUsModalOpened] = useState(false);

  const { locale } = useLocale();
  const { toggleLocaleSettingsModal } = useContext(GlobalContext);
  const { logout, user } = useAuth();

  return (
    <ul className="flex flex-col mt-8 px-4 pb-4">
      {/* <li>
        <Link
          to="#"
          className={styles.listItemChild}
        >
          <IconDescription width={18} className="mr-3" />
          {t('Documents')}
        </Link>
      </li> */}
      <PWAInstallSidebarButton buttonStyle={styles.listItemChild} />
      <li>
        <NavLink
          to={ROUTE_CONTRACTS}
          activeClassName="!text-gray-600"
          className={classNames('no-underline', styles.listItemChild)}
        >
          <IconTerms width={18} className="mr-3" />
          {t('Your agreement')}
        </NavLink>
      </li>
      <li>
        <button
          className={styles.listItemChild}
          onClick={() => setContactUsModalOpened(true)}
        >
          <IconChat width={18} className={'mr-3'} />
          {t('Contact us')}
        </button>
        {contactUsModalOpened && (
          <ContactUsModal
            opened={contactUsModalOpened}
            onClose={() => setContactUsModalOpened(false)}
          />
        )}
      </li>
      <li>
        <button
          className={classNames('uppercase', styles.listItemChild)}
          onClick={toggleLocaleSettingsModal}
          title={t('Choose a language')}
        >
          <IconGlobe width={18} className="mr-3" />
          {languageCode(locale)}
        </button>
      </li>
      <li>
        <Dropdown
          position="top-right"
          highlightedActiveButton={false}
          buttonClassName={styles.listItemChild}
          items={() => (
            <button
              onClick={logout}
              className="w-full flex items-center px-5 py-3 md:py-4 text-base md:text-sm no-underline text-gray-600 capitalize transition-colors duration-200 hover:bg-gray-100"
            >
              <IconSignOut
                width={18}
                className="mr-3 flex-shrink-0 self-start"
              />
              {t('Sign out')}
            </button>
          )}
        >
          <IconAccountCircled width={18} className="mr-3" />
          {user && formatUserName(user)}
        </Dropdown>
      </li>
    </ul>
  );
}
