import { IconProps } from './IconProps';

export default function IconBurger(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      fill="currentColor"
      viewBox="0 0 42 42"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M4 10.5h34v-3H4v3zm0 21h34v-3H4v3zM38 21H4v-3h34v3z"
        clipRule="evenodd"
      />
    </svg>
  );
}
