import useMainContainerRefContext from 'hooks/useMainContainerRefContext/useMainContainerRefContext';

export default function useMainContainerScroll() {
  const mainContainerRef = useMainContainerRefContext();

  return {
    scrollTo: (x: number = 0, y: number = 0, smooth: boolean = true) => {
      if (smooth) {
        mainContainerRef?.scrollTo({ top: y, left: x, behavior: 'smooth' });
      } else {
        mainContainerRef?.scrollTo(x, y);
      }
    },
  };
}
