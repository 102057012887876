import login from 'api/auth/login';
import logout from 'api/auth/logout';
import axios from 'axios';
import LoadingContainer from 'components/layout/LoadingContainer/LoadingContainer';
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import AuthManager, {
  EventType,
  SubscribeCallback,
} from 'utils/auth/authManager';
import { isDevelopment } from 'utils/environment';

type AuthProviderProps = {
  children: ReactNode;
};

export type AuthState = {
  login(email: string, password: string): Promise<unknown>;
  logout(): void;
  isAuthenticated: boolean;
  user: User | null;
};

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  logging: boolean;
};

type LocationState = {
  referrer?: string;
};

const isDev = isDevelopment();

const AuthContext = createContext<AuthState | undefined>(undefined);

// Save token to session storage in dev environment to not call refresh-jwt every time hot reloading triggers
const initialIsAuthenticated = isDev ? !!AuthManager.getToken() : false;

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(
    initialIsAuthenticated
  );
  const [refreshTokenAllowed, setRefreshTokenAllowed] = useState(true);

  const [user, setUser] = useState<User | null>(null);
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation<LocationState>();

  const subscribeHandler = useCallback<SubscribeCallback>(
    (type) => {
      // Remove auth state when token is no londer valid
      if (type === EventType.Removed) {
        if (isAuthenticated) {
          toast.error(t('Please sign in again'));
        }
        setIsAuthenticated(false);
      }

      if (type === EventType.Added) {
        setIsAuthenticated(true);
      }
    },
    [t, isAuthenticated]
  );

  useEffect(() => {
    AuthManager.subscribe(subscribeHandler);

    // Unsubscribe when hook is unmounted
    return () => {
      AuthManager.unsubscribe(subscribeHandler);
    };
  }, [subscribeHandler]);

  useEffect(() => {
    // Call refresh token when user comes first time on page
    // Prevent multiple calls by setting refreshTokenAllowed to false
    if (!isAuthenticated && refreshTokenAllowed) {
      setRefreshTokenAllowed(false);

      AuthManager.refresh()
        .catch((e) => {
          console.error(e);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated, refreshTokenAllowed]);

  useEffect(() => {
    if (!isAuthenticated) {
      setUser(null);
      AuthManager.removeToken();
      return;
    }

    // Fetch user and also check if token is valid.
    // Endpoint returns 401 if token is invalid
    axios
      .get<User>('/user-info')
      .then(({ data }) => {
        setUser(data);
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
        AuthManager.removeToken();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isAuthenticated]);

  const loginCallback = useCallback(
    (email: string, password: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await login(email, password, true);

          AuthManager.setToken(data.accessToken);
          setIsAuthenticated(true);
          resolve(true);

          // Redirect back to intended page
          const referrer = location.state.referrer;
          if (referrer) {
            history.push(referrer);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    [history, location?.state?.referrer]
  );

  const logoutCallback = useCallback(() => {
    setRefreshTokenAllowed(false);
    logout();
    setIsAuthenticated(false);
  }, []);

  const value = useMemo(
    () => ({
      login: loginCallback,
      logout: logoutCallback,
      isAuthenticated,
      user,
    }),
    [loginCallback, logoutCallback, isAuthenticated, user]
  );

  if (isLoading) {
    return <LoadingContainer />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
