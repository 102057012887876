import { ReactNode, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BoatFormState } from 'types/fleet';

type FleetFormLabels = Record<keyof Omit<BoatFormState, 'status'>, string>;

type FleetFormLabelsProviderProps = {
  children?: ReactNode;
};

const FleetFormLabelsContext = createContext<FleetFormLabels | undefined>(
  undefined
);

export default function FleetFormLabelsProvider({
  children,
}: FleetFormLabelsProviderProps) {
  const { t } = useTranslation();

  const labels: FleetFormLabels = {
    // Basic Details
    name: t('Boat name'),
    category: t('Yacht type'),
    manufacturer: t('Manufacturer'),
    model: t('Model'),
    marina: t('Marina'),
    // Parameters
    length: t('Length'),
    beam: t('Beam'),
    draft: t('Draft'),
    year: t('Year'),
    renovated_year: t('Refitted in'),
    sail_renovated_year: t('Sail refitted in'),
    engine_power: t('Engine power'),
    number_engines: t('Number of engines'),
    total_engine_power: t('Total engine power'),
    engine_type: t('Engine type'),
    fuel: t('Fuel tank'),
    cruising_consumption: t('Fuel consumption'),
    maximum_speed: t('Maximum speed'),
    water_tank: t('Water tank'),
    waste_tank: t('Waste tank'),
    toilets: t('Total toilets'),
    electric_toilets: t('Electric toilets'),
    single_cabins: t('Single cabin'),
    double_cabins: t('Double cabin'),
    triple_cabins: t('Triple cabin'),
    quadruple_cabins: t('Quadruple cabin'),
    cabins_with_bunk_bed: t('Double cabin (bunk beds)'),
    saloon_sleeps: t('Beds in saloon'),
    crew_sleeps: t('Beds for the crew'),
    max_sleeps: t('Maximum sleeps'),
    max_people: t('Maximum people'),
    // Amenities
    cockpit: t('Saloon and cabins'),
    entertainment: t('Entertainment'),
    equipment: t('Equipment'),
    mainsail: t('Mainsail'),
    pet: t('Pets'),
    captain: t('Rental type'),
    licence: t('Sailing licence required'),
    // Gallery
    gallery: t('Gallery'),
    // Note (Fleet create form only)
    note: t('Extra note'),
  };

  return (
    <FleetFormLabelsContext.Provider value={labels}>
      {children}
    </FleetFormLabelsContext.Provider>
  );
}

export { FleetFormLabelsContext, FleetFormLabelsProvider };
