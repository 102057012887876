import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth, useLocale, useSyncCharterIdUrl } from 'hooks';
import { ROUTE_CHARTER_LIST } from 'utils/constants/routes';
import extractCharterIdFromPath from 'utils/url/extractCharterIdFromPath';
import generateRoute from 'utils/url/generateRoute';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import LoadingContainer from 'components/layout/LoadingContainer/LoadingContainer';
import { Charter } from 'types/charter';
import fetchCharters from 'api/charter/fetchCharters';

export type CharterUserState = {
  loading: boolean;
  charter: Charter | null;
  changeCharter: (charter: any) => void;
  charters: Charter[];
  activeCharters: Charter[];
};

type CharterProviderProps = {
  children: ReactNode;
};

const CharterContext = createContext<CharterUserState | undefined>(undefined);

const CharterProvider = ({ children }: CharterProviderProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [charters, setCharters] = useState<Charter[]>([]);
  const [charter, setCharter] = useState<Charter | null>(null);

  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const { locale } = useLocale();

  const activeCharters = useMemo(
    () => charters.filter((c) => c.status === 'ACTIVE'),
    [charters]
  );

  useEffect(() => {
    if (!isAuthenticated) {
      // Reset the state when user is logged out
      setLoading(false);
      setCharter(null);
      setCharters([]);

      return;
    }

    setLoading(true);

    fetchCharters()
      .then((res) => {
        setCharters(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isAuthenticated]);

  useEffect(() => {
    if (charters.length && !charter) {
      // Set active charter from url path
      const id = extractCharterIdFromPath(history.location.pathname);
      if (id) {
        const activeCharterCandidate = activeCharters.find((c) => c.id === id);
        if (activeCharterCandidate) {
          setCharter(activeCharterCandidate);
          return;
        }

        toast.error(
          t(
            'You are not authorised to access this link. Please contact your account manager.'
          )
        );
      }

      // Invalid active charter id or no active charter selected -> redirect to choice list
      history.push(generateRoute(ROUTE_CHARTER_LIST, locale));
    }
  }, [activeCharters, charter, charters.length, history, locale, t]);

  useSyncCharterIdUrl(charter);

  useEffect(() => {
    // Set first charter as default if there is only one charter
    if (activeCharters.length === 1) {
      setCharter(activeCharters[0]);
    }
  }, [activeCharters]);

  const value = useMemo(
    () => ({
      loading,
      charters,
      activeCharters,
      charter,
      changeCharter: setCharter,
    }),
    [loading, charters, activeCharters, charter]
  );

  if (loading) {
    return <LoadingContainer />;
  }

  return (
    <CharterContext.Provider value={value}>{children}</CharterContext.Provider>
  );
};

export { CharterContext, CharterProvider };
