import { ProgressColor } from 'components/progress/types';
import { useEffect, useState } from 'react';

function calcProgressColor(value: number): ProgressColor {
  if (value >= 80) {
    return 'green';
  } else if (value >= 60) {
    return 'yellow';
  } else {
    return 'red';
  }
}

export default function useProgress(finalValue: number) {
  const [value, setValue] = useState(0);
  const color = calcProgressColor(finalValue);

  // keep value as 0 by default and then update it to trigger the css animation
  useEffect(() => {
    setValue(finalValue);
  }, [finalValue]);

  return { value, color };
}
