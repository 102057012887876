import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUrlSearchParams } from 'hooks';
import { useLocation } from 'react-router';

const PAGE_PARAM = 'page';
const DEFAULT_PAGE = 1;

export default function usePagination(perPage: number) {
  const { searchParams, setSearchParam } = useUrlSearchParams();
  const location = useLocation();

  const extractPageFromUrl = useCallback(
    () => Math.abs(Number(searchParams.get(PAGE_PARAM))) || DEFAULT_PAGE,
    [searchParams]
  );

  const [page, setPage] = useState(extractPageFromUrl);
  const [pagesCount, setPagesCount] = useState(0);

  useEffect(() => {
    setPage(extractPageFromUrl());
  }, [location, extractPageFromUrl]);

  const changePage = useCallback(
    (newPage: number) => {
      if (newPage > pagesCount) {
        newPage = pagesCount;
      }

      setPage(newPage);
      setSearchParam(PAGE_PARAM, newPage.toString());
    },
    [pagesCount, setSearchParam]
  );

  const initializePagination = useCallback(
    (itemsCount: number) => {
      if (itemsCount > 0) {
        // There are items on this page -> initialize pagination
        setPagesCount(Math.ceil(itemsCount / perPage));
      } else if (page !== 1) {
        // There are no items on page other than page 1 -> redirect to page 1
        setPage(DEFAULT_PAGE);
        setSearchParam(PAGE_PARAM, DEFAULT_PAGE.toString());
      } else {
        // There are no items even on page 1 -> initialize pagination (and display 'No content' message)
        setPagesCount(0);
      }
    },
    [page, perPage, setSearchParam]
  );

  const offset = page ? (page - 1) * perPage : 0;

  const value = useMemo(
    () => ({
      page,
      changePage,
      offset,
      pagesCount,
      initializePagination,
    }),
    [page, changePage, offset, pagesCount, initializePagination]
  );

  return value;
}
