import { Trans, useTranslation } from 'react-i18next';

export default function Error() {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        <h2 className="mb-5">{t('Something went wrong. Please try again.')}</h2>
        <div className="text-sm">
          <Trans>
            Please <a href={window.location.href}>try again</a> or contact
            support if the problem persists.
          </Trans>
        </div>
      </div>
    </div>
  );
}
