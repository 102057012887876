import { IconProps } from './IconProps';

export default function IconOrders(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      viewBox="0 0 36 40"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00003 8.57143C8.00003 13.3053 11.8376 17.1429 16.5715 17.1429C21.3053 17.1429 25.1429 13.3053 25.1429 8.57143C25.1429 3.83756 21.3053 0 16.5715 0C11.8376 0 8.00003 3.83756 8.00003 8.57143ZM22.2857 8.57143C22.2857 11.7273 19.7274 14.2857 16.5715 14.2857C13.4155 14.2857 10.8572 11.7273 10.8572 8.57143C10.8572 5.41551 13.4155 2.85714 16.5715 2.85714C19.7274 2.85714 22.2857 5.41551 22.2857 8.57143Z"
      />
      <path d="M0.857178 35.7143C0.857178 28.521 5.61985 22.3825 13.2389 20.4778C13.1501 20.7231 13.0645 20.953 12.9835 21.1707C12.6305 22.1194 12.3637 22.8363 12.2865 23.5885C7.29243 25.3533 3.71432 30.1159 3.71432 35.7143H0.857178Z" />
      <path d="M29.4286 28.5714H22.7619V25.7143H29.4286V28.5714Z" />
      <path d="M29.4286 34.2857H22.7619V31.4286H29.4286V34.2857Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8572 20C18.7532 20 17.0477 21.7056 17.0477 23.8095V36.1905C17.0477 38.2944 18.7532 40 20.8572 40H31.3334C33.4373 40 35.1429 38.2944 35.1429 36.1905V23.8095C35.1429 21.7056 33.4373 20 31.3334 20H20.8572ZM31.3334 22.8571H20.8572C20.3312 22.8571 19.9048 23.2835 19.9048 23.8095V36.1905C19.9048 36.7165 20.3312 37.1429 20.8572 37.1429H31.3334C31.8594 37.1429 32.2857 36.7165 32.2857 36.1905V23.8095C32.2857 23.2835 31.8594 22.8571 31.3334 22.8571Z"
      />
    </svg>
  );
}
