import { useState, useEffect } from 'react';

function getStorageValue<T>(key: string, defaultValue: T): T {
  if (typeof window === 'undefined') {
    throw new Error('Browser window undefined');
  }

  const savedValue = localStorage.getItem(key);
  return savedValue !== null ? JSON.parse(savedValue) : defaultValue;
}

// defaultValue is omitted if a value already exists in local storage
export default function useLocalStorage<T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] {
  const [value, setValue] = useState(() => getStorageValue(key, defaultValue));

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
