import fetchAccountManagerByCharterId from 'api/fetchAccountManagerByCharterId';
import Modal from 'components/modals/Modal/Modal';
import { useReactQuery } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import IconContactUs from 'components/modals/ContactUsModal/IconContactUs';
import { ACCOUNT_MANAGER } from 'utils/constants/queryKeys';

export type ContactUsModalProps = {
  opened: boolean;
  onClose: () => void;
};

export default function ContactUsModal({
  opened,
  onClose,
}: ContactUsModalProps) {
  const { t } = useTranslation();
  const { createQueryKey } = useReactQuery();

  const queryKey = createQueryKey('general', ACCOUNT_MANAGER);
  const { data } = useQuery(queryKey, () =>
    fetchAccountManagerByCharterId(queryKey[0])
  );

  return (
    <Modal title={t('Contact us')} isOpen={opened} onRequestClose={onClose}>
      <div className="flex flex-col items-center text-center">
        <div className="mt-4 mb-6">
          <IconContactUs />
        </div>

        <ul>
          <li className="pb-5">
            <p className="uppercase text-gray-400 text-sm">
              {t('Account manager')}
            </p>
            <p>{data ? data.name : '\u00A0'}</p>
          </li>
          <li className="pb-5">
            <p className="uppercase text-gray-400 text-sm">
              {t('Telephone number')}
            </p>
            <a href={`tel:${data?.phone}`} className="no-underline">
              {data ? data.phone : '\u00A0'}
            </a>
          </li>
          <li className="pb-5">
            <p className="uppercase text-gray-400 text-sm">{t('Email')}</p>
            <a href={`mailto:${data?.email}`} className="no-underline">
              {data ? data.email : '\u00A0'}
            </a>
          </li>
        </ul>
      </div>
    </Modal>
  );
}
