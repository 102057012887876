import { Settings } from 'types/availability';

export const CALENDAR_CELL_WIDTH = 48;

export const MAX_MONTHS_IN_VIEW = 18;

export const DEFAULT_AVAILABILITY_SETTINGS: Settings = {
  startingDayOfWeek: 'Saturday',
};

export enum Action {
  OPEN_EDIT = 1,
  OPEN_READONLY = 2,
  CLOSE_EDIT = 3,
  CLOSE_READONLY = 4,
  BT_RESERVED = 5,
}

export const ACTION_COLORS = {
  [Action.OPEN_READONLY]: 'rgb(219, 255, 234)',
  [Action.OPEN_EDIT]: 'rgb(164, 254, 208)',
  [Action.CLOSE_EDIT]: 'rgb(253, 231, 230)',
  [Action.CLOSE_READONLY]: 'rgb(254, 245, 246)',
  [Action.BT_RESERVED]: 'rgb(230, 237, 254)',
};

export const ACTION_BACKGROUND_CLASSES = {
  [Action.OPEN_READONLY]: 'bg-teal-50',
  [Action.OPEN_EDIT]: 'bg-teal-100',
  [Action.CLOSE_EDIT]: 'bg-red-100',
  [Action.CLOSE_READONLY]: 'bg-red-50',
  [Action.BT_RESERVED]: 'bg-blue-100',
};
