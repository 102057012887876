import { useContext } from 'react';
import { LocaleContext } from 'contexts/LocaleContext';

const useLocale = () => {
  const context = useContext(LocaleContext);
  if (context === undefined) {
    throw Error('useLocale must be used within a LocaleContextProvider');
  }

  return context;
};

export default useLocale;
