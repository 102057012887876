import locales from 'i18n/locales';
import { Locale } from 'i18n/types';
import trimLeadingSlash from 'utils/trimLeadingSlash';
import languageCode from './languageCode';

const defaultLocale: Locale = 'en-GB';

export default function detectLocaleFromPath(): Locale {
  const urlLocale = trimLeadingSlash(window.location.pathname).substr(0, 2);
  const locale = locales.find(
    (locale) => languageCode(locale as Locale) === urlLocale
  );

  if (locale) {
    return locale as Locale;
  }

  return defaultLocale;
}
