import { IconProps } from './IconProps';

export default function IconSearch(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M11.285 12.0932C10.0904 13.1045 8.54497 13.7143 6.85714 13.7143C3.07005 13.7143 0 10.6442 0 6.85714C0 3.07005 3.07005 0 6.85714 0C10.6442 0 13.7143 3.07005 13.7143 6.85714C13.7143 8.54497 13.1045 10.0904 12.0932 11.285L15.8326 15.0245L15.0245 15.8326L11.285 12.0932ZM12.5714 6.85714C12.5714 3.70123 10.0131 1.14286 6.85714 1.14286C3.70123 1.14286 1.14286 3.70123 1.14286 6.85714C1.14286 10.0131 3.70123 12.5714 6.85714 12.5714C10.0131 12.5714 12.5714 10.0131 12.5714 6.85714Z" />
    </svg>
  );
}
