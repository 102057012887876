import { AxiosRequestConfig } from 'axios';
import AuthManager from 'utils/auth/authManager';
import { API_BASE_URL } from 'utils/constants/environment';

const fullUrlFromConfig = (config: AxiosRequestConfig) =>
  config.baseURL ? `${config.baseURL}/${config.url}` : config.url || '';

export default async function authRequestInterceptor(
  config: AxiosRequestConfig
) {
  const requestUrl = fullUrlFromConfig(config);
  // Ignore for other requests than our API
  if (!requestUrl.startsWith(API_BASE_URL || '')) {
    return config;
  }

  let token = AuthManager.getToken();
  // Auto refresh token if needed
  if (AuthManager.isExpired() || AuthManager.willExpireSoon()) {
    await AuthManager.refresh();
    token = AuthManager.getToken();
  }

  if (!config.headers) {
    config.headers = {};
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}
