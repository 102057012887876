import axios from 'axios';
import { BookingStatus } from 'types/order';

export type UpdateOrderStatusData = {
  orderId: string;
  status: 'APPROVED' | 'DECLINED';
  message?: string;
  charterId?: string;
};

export type UpdateOrderStatusResponse = {
  status: BookingStatus;
};

export default async function updateOrderStatus({
  charterId,
  orderId,
  ...data
}: UpdateOrderStatusData) {
  const { data: responseData } = await axios.put(
    `/charters/${charterId}/orders/${orderId}`,
    data
  );

  return responseData as unknown as UpdateOrderStatusResponse;
}
