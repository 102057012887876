import { IconProps } from './IconProps';

export default function IconShareIOS(props: IconProps) {
  return (
    <svg
      width={18}
      viewBox="0 0 18 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.474 6.754H2.7C1.209 6.754 0 8.105 0 9.628v11.615C0 22.766 1.209 24 2.7 24h12.6c1.491 0 2.7-1.234 2.7-2.757V9.628c0-1.523-1.209-2.874-2.7-2.874h-2.879v1.72H15.3c.497 0 1.135.646 1.135 1.154v11.615c0 .508-.638 1.155-1.135 1.155H2.7c-.497 0-1.135-.647-1.135-1.155V9.628c0-.508.638-1.272 1.135-1.272h2.774V6.754Z" />
      <path d="M9.57.346A.719.719 0 0 0 8.939 0a.719.719 0 0 0-.632.346L4.68 3.93a.702.702 0 0 0 0 1 .722.722 0 0 0 1.013 0l2.42-2.391v12.382c0 .442.363.8.81.8.447 0 .81-.358.81-.8V2.508l2.45 2.423a.722.722 0 0 0 1.014 0 .702.702 0 0 0 0-1.001L9.57.346Z" />
    </svg>
  );
}
