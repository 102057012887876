import { IconProps } from './IconProps';

export default function IconLocation(props: IconProps) {
  return (
    <svg
      viewBox="0 0 28 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6253 42.3959L13.6234 42.3978C13.0324 41.8069 12.3639 41.0373 11.6483 40.1279C6.91478 34.1123 0.123392 21.9799 0.123392 14.9827C0.123392 6.92777 6.16753 0.397949 13.6234 0.397949C21.0792 0.397949 27.1233 6.92777 27.1233 14.9827C27.1233 21.9799 20.3319 34.1123 15.5984 40.1279C14.8836 41.0363 14.2158 41.8052 13.6253 42.3959ZM13.6234 37.7791C15.395 35.4534 17.514 32.1812 19.4126 28.6139C20.7688 26.0657 21.9658 23.4532 22.817 21.0354C23.6826 18.5768 24.1233 16.5064 24.1233 14.9827C24.1233 8.36207 19.2082 3.39794 13.6234 3.39794C8.0385 3.39794 3.12338 8.36207 3.12338 14.9827C3.12338 16.5064 3.56406 18.5768 4.42968 21.0354C5.28092 23.4532 6.47792 26.0657 7.83415 28.6139C9.73274 32.1812 11.8517 35.4534 13.6234 37.7791Z"
        fill="currentColor"
      />
      <path
        d="M13.6234 17.6479C11.5523 17.6479 9.87336 15.969 9.87336 13.8979C9.87336 11.8268 11.5523 10.1479 13.6234 10.1479C15.6944 10.1479 17.3733 11.8268 17.3733 13.8979C17.3733 15.969 15.6944 17.6479 13.6234 17.6479ZM13.6234 20.6479C17.3513 20.6479 20.3733 17.6258 20.3733 13.8979C20.3733 10.17 17.3513 7.14793 13.6234 7.14793C9.89544 7.14793 6.87337 10.17 6.87337 13.8979C6.87337 17.6258 9.89544 20.6479 13.6234 20.6479Z"
        fill="currentColor"
      />
    </svg>
  );
}
