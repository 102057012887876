import useRoute from 'hooks/useRoute/useRoute';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import routesFactory from 'routes';

type RouteBack =
  | {
      path: string;
      params: {};
    }
  | undefined;

export default function useRouteBack() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const route = useMemo(() => {
    let routeBack: RouteBack;

    routesFactory(t).find((route) => {
      const match = matchPath(pathname, {
        path: route.path,
        exact: true,
      });
      const pathBack = route.pathBack;

      if (match && pathBack) {
        routeBack = { path: pathBack, params: match.params };
        return true;
      }

      return false;
    });

    return routeBack;
  }, [pathname, t]);

  const usableRoute = useRoute(route?.path || '', route?.params);

  return useMemo(() => (route ? usableRoute : undefined), [route, usableRoute]);
}
