import { IconProps } from './IconProps';

export default function IconFilter(props: IconProps) {
  return (
    <svg
      width={18}
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.724 2.572a2.572 2.572 0 0 1 4.98 0H18v1.286h-3.295a2.572 2.572 0 0 1-4.981 0H0V2.572h9.724Zm1.205.643a1.286 1.286 0 1 0 2.571 0 1.286 1.286 0 0 0-2.571 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.724 14.143a2.573 2.573 0 0 1 4.98 0H18v1.286h-3.295a2.572 2.572 0 0 1-4.981 0H0v-1.286h9.724Zm1.205.643a1.286 1.286 0 1 0 2.57 0 1.286 1.286 0 0 0-2.57 0Z"
        fill="currentColor"
      />
      <path
        d="M5.786 11.572a2.572 2.572 0 0 1-2.49-1.929H0V8.358h3.295a2.572 2.572 0 0 1 4.981 0H18v1.285H8.276a2.573 2.573 0 0 1-2.49 1.929Zm0-1.286a1.286 1.286 0 1 0 0-2.571 1.286 1.286 0 0 0 0 2.571Z"
        fill="currentColor"
      />
    </svg>
  );
}
