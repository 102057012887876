import { CharterContext } from 'contexts/CharterContext';
import { useContext } from 'react';

const useCharter = () => {
  const context = useContext(CharterContext);

  if (context === undefined) {
    throw Error('useCharter must be used within a CharterProvider');
  }

  return context;
};

export default useCharter;
