import { IconProps } from './IconProps';

export default function IconDescription(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      fill="currentColor"
      viewBox="0 0 43 43"
      {...props}
    >
      <path d="M12.724 13.693h18v-3h-18v3zM30.724 22.693h-18v-3h18v3zM12.724 31.693h18v-3h-18v3z" />
      <path
        fillRule="evenodd"
        d="M8.224.193a3 3 0 00-3 3v36a3 3 0 003 3h27a3 3 0 003-3v-36a3 3 0 00-3-3h-27zm0 3h27v36h-27v-36z"
        clipRule="evenodd"
      />
    </svg>
  );
}
