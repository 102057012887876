import axios from 'axios';
import { CharterOrderStatus } from 'types/order';

type Response = {
  count: number;
};

export default async function fetchOrdersCountByCharterId(
  charterId: string,
  charterStatus: CharterOrderStatus
) {
  const { data } = await axios.get<Response>(
    `/charters/${charterId}/orders/count?charterStatus=${charterStatus}`
  );

  return data;
}
