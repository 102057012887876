import { IconProps } from './IconProps';

export default function IconMinus(props: IconProps) {
  return (
    <svg
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71436 5.57129L10.2858 5.57129V6.42843L1.71436 6.42843V5.57129Z"
      />
    </svg>
  );
}
