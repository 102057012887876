import { generatePath } from 'react-router-dom';
import { Locale } from 'i18n/types';
import { languageCode } from 'utils/i18n';
import trimLeadingSlash from 'utils/trimLeadingSlash';

type Params = {
  [key: string]: string | number | boolean | undefined;
};

export default function generateRoute(
  path: string,
  locale: Locale,
  params: Params = {}
) {
  return generatePath(`/:locale/${trimLeadingSlash(path)}`, {
    locale: languageCode(locale),
    ...params,
  });
}
