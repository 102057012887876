import { Timeline } from 'types/calendar';
import {
  PricesOptions,
  PricesList,
  PricesWithDiscountsGroupedByBoat,
  DiscountsList,
} from 'types/discount';
import { CharterActiveBoatsList } from 'types/fleet';
import createDiscountTimeline from './createDiscountTimeline';

export default function processPricesWithDiscounts(
  timeline: Timeline,
  pricesOptions: PricesOptions,
  prices: PricesList,
  filteredBoats: CharterActiveBoatsList,
  filteredValidDiscounts: DiscountsList,
  pricesIndexFrom: number,
  pricesIndexTo: number
) {
  const mode = pricesOptions.mode === 'period' ? 'periods' : 'days';
  const daysTimeline = timeline.days;
  const startOfTimeline = daysTimeline[0];
  const endOfTimeline = daysTimeline[daysTimeline.length - 1];

  const data: PricesWithDiscountsGroupedByBoat = {};
  const boatsSlice = filteredBoats.slice(pricesIndexFrom, pricesIndexTo);

  // Create group (boat) objects
  boatsSlice.reduce((dataObject, { id }) => {
    dataObject[id] = {};
    return dataObject;
  }, data);

  // Fill group objects with initial date objects based on timeline
  Object.values(data).forEach((pricesByDateObj) => {
    timeline[mode].forEach((date) => {
      pricesByDateObj[date] = {
        date,
        price: null,
        currency: null,
        topDiscountValue: null,
        discounts: [],
      };
    });
  });

  // Assign price data to existing date objects
  boatsSlice.forEach(({ id }) => {
    const price = prices.find((price) => id === price.boat_id);

    if (price) {
      price.items.forEach(({ date, price, currency }) => {
        if (data[id][date]) {
          data[id][date].price = price;
          data[id][date].currency = currency;
        }
      });
    }
  });

  // Assign discount data to existing date objects
  filteredValidDiscounts.forEach((discount) => {
    const discountTimeline = createDiscountTimeline(
      discount.from,
      discount.to,
      startOfTimeline,
      endOfTimeline,
      pricesOptions
    );

    discount.boats.forEach((boatId) => {
      discountTimeline[mode].forEach((date) => {
        const discountAmount = discount.amount;

        // Boat or date might not be found in 'data' object (for example if the date range of discount is in the past)
        const dataExists = data[boatId] && data[boatId][date];

        if (dataExists) {
          data[boatId][date].discounts.push(discount);

          if (
            discount.active &&
            discountAmount > (data[boatId][date].topDiscountValue || 0)
          ) {
            data[boatId][date].topDiscountValue = discountAmount;
          }
        }
      });
    });
  });

  return data;
}
