import { MinMaxNumber } from 'types';
import { DiscountsList } from 'types/discount';
import { filterByAmount, filterByName } from 'utils/filters/discounts';

type Filters = {
  name?: string;
  amount?: MinMaxNumber;
};

export default function filterDiscounts(
  discounts: DiscountsList,
  { name, amount }: Filters
) {
  if (name) {
    discounts = filterByName(discounts, name);
  }
  if (amount) {
    discounts = filterByAmount(discounts, amount);
  }

  return discounts;
}
