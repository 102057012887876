import Modal from 'components/modals/Modal/Modal';
import { useTranslation } from 'react-i18next';
import CharterChoiceList from 'views/CharterSelection/CharterChoiceList/CharterChoiceList';

export type CharterChoiceModalProps = {
  opened: boolean;
  onClose: () => void;
};

export default function CharterChoiceModal({
  opened,
  onClose,
}: CharterChoiceModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Select charter company')}
      isOpen={opened}
      onRequestClose={onClose}
    >
      <CharterChoiceList />
    </Modal>
  );
}
