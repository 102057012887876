import useWindowSize from 'hooks/useWindowSize/useWindowSize';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Timeline } from 'types/calendar';

export default function useCalendarTableCache(
  timeline: Timeline,
  cellWidth: number
) {
  const tableRef = useRef<HTMLDivElement>(null);

  const [startPosition, setStartPosition] = useState(0);
  const [panelWidth, setPanelWidth] = useState(0);

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (tableRef.current) {
      setPanelWidth(tableRef.current.clientWidth);
    }
  }, [windowWidth]);

  const maxVisibleColumns = useMemo(() => {
    return Math.ceil(panelWidth / cellWidth);
  }, [cellWidth, panelWidth]);

  const cachedTimeline = useMemo<Timeline>(() => {
    if (maxVisibleColumns === 0) {
      return { days: [], periods: [] };
    }

    const startIndex =
      (startPosition === 0 ? startPosition : startPosition - 1) *
      Math.ceil(maxVisibleColumns / 2);

    const endIndex =
      startIndex +
      Math.ceil(maxVisibleColumns * (startPosition === 0 ? 1.5 : 2.5) + 1);

    return {
      days: timeline.days.slice(startIndex, endIndex),
      periods: timeline.periods.slice(startIndex, endIndex),
    };
  }, [maxVisibleColumns, timeline, startPosition]);

  return useMemo(
    () => ({
      tableRef,
      startPosition,
      setStartPosition,
      maxVisibleColumns,
      cachedTimeline,
    }),
    [maxVisibleColumns, startPosition, cachedTimeline]
  );
}
