import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'react-i18next';
import {
  AgencyPaymentStatus,
  BookingStatus,
  CharterOrderStatus,
  ClientPaymentStatus,
} from 'types/order';

type OrderStatusLabels = Record<BookingStatus, TFunction<string>>;
type ClientPaymentStatusLabels = Record<ClientPaymentStatus, TFunction<string>>;
type AgencyPaymentStatusLabels = Record<AgencyPaymentStatus, TFunction<string>>;
type CharterOrderStatusLabels = Record<CharterOrderStatus, TFunction<string>>;

export default function useOrderStatusLabels() {
  const { t } = useTranslation();

  return useMemo(() => {
    const orderStatusLabels: OrderStatusLabels = {
      'NEW': t('New'),
      'IN PROCESS': t('In process'),
      'CONFIRMED': t('Confirmed'),
      'CANCELLED': t('Cancelled'),
      'COMPLETED': t('Completed'),
    };

    const clientPaymentStatusLabels: ClientPaymentStatusLabels = {
      'UNPAID': t('Not paid'),
      'PARTIALLY PAID': t('Partially paid'),
      'PAID': t('Paid'),
      'REFUNDED': t('Refunded'),
    };

    const agencyPaymentStatusLabels: AgencyPaymentStatusLabels = {
      'UNPAID': t('Not paid'),
      'PARTIALLY PAID': t('Partially paid'),
      'PAID': t('Paid'),
      'REFUNDED': t('Refunded'),
    };

    const charterOrderStatusLabels: CharterOrderStatusLabels = {
      APPROVED: t('Approved'),
      DECLINED: t('Declined'),
      PENDING: t('Pending'),
    };

    return {
      orderStatusLabels,
      clientPaymentStatusLabels,
      agencyPaymentStatusLabels,
      charterOrderStatusLabels,
    };
  }, [t]);
}
