import { IconProps } from './IconProps';

export default function IconBoatLength(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      fill="currentColor"
      viewBox="0 0 10 14"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m5.325 10.129.974-.886.657.723-2.105 1.914-2.28-1.9.625-.75 1.153.96V5.01l-.974.885-.657-.723 2.119-1.926 2.12 1.926-.658.723-.974-.885v5.118Z"
      />
      <path d="M4.837 1.421c2.176 1.194 3.581 3.342 3.581 5.742 0 .845-.138 2.15-.422 3.4-.24 1.05-.551 1.922-.882 2.46H2.56c-.33-.538-.642-1.41-.88-2.46-.285-1.25-.423-2.555-.423-3.4 0-2.4 1.405-4.548 3.58-5.742Zm0-1.095C2.13 1.604.279 4.186.279 7.163c0 1.854.595 5.509 1.79 6.837h5.535C8.8 12.672 9.395 9.017 9.395 7.163c0-2.977-1.85-5.56-4.558-6.837Z" />
    </svg>
  );
}
