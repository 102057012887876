import { Reducer, ReducerState, useMemo, useReducer } from 'react';

export default function useFilters<R extends Reducer<any, any>>(
  reducer: R,
  initialState: ReducerState<R>
) {
  const [filters, dispatchFilters] = useReducer(reducer, initialState);

  const activeFiltersCount = useMemo(
    () =>
      Object.values(filters).reduce<number>((count, value) => {
        if (Array.isArray(value)) {
          if (value.length) {
            ++count;
          }
        } else if (value) {
          ++count;
        }
        return count;
      }, 0),
    [filters]
  );

  return useMemo(
    () => ({
      filters,
      changeFilters: dispatchFilters,
      activeFiltersCount,
    }),
    [activeFiltersCount, filters]
  );
}
