export type SpinnerLoaderProps = {
  width?: number;
};

export default function SpinnerLoader({ width = 30 }: SpinnerLoaderProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 202 202"
      className="animate-spin"
      width={width}
    >
      <path
        stroke="#BCC2D2"
        strokeWidth="30"
        d="M143.5 174.612c-40.655 23.472-92.64 9.543-116.112-31.112C3.916 102.845 17.845 50.86 58.5 27.388 99.155 3.916 151.14 17.845 174.612 58.5c23.472 40.655 9.543 92.64-31.112 116.112z"
      />
      <path
        fill="#828FAB"
        d="M190.186 55.763A100.538 100.538 0 00187.603 51C159.989 3.17 98.83-13.217 51 14.397 33.55 24.473 20.284 39.014 11.815 55.743l35.755.004A69.875 69.875 0 0166 40.378c29.797-17.203 66.984-10.01 88.432 15.381l35.754.004z"
      />
    </svg>
  );
}
