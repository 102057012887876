import { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import {
  useAuth,
  useCharter,
  useDocumentTitle,
  useRouteLocaleResolver,
  useRoutes,
  useScrollbarWidth,
  useSentryContext,
  useTracking,
  useViewportHeightUnit,
} from 'hooks';
import Navigation from 'components/layout/Navigation/Navigation';
import LocaleSettingsModal from 'components/modals/LocaleSettingsModal/LocaleSettingsModal';
import { Toaster } from 'react-hot-toast';
import PWAInstallPopupIOS from 'components/pwa/PWAInstallPopupIOS/PWAInstallPopupIOS';
import { browserName, osName } from 'utils/platform';

function App() {
  useSentryContext();
  useRouteLocaleResolver();
  useViewportHeightUnit();
  useScrollbarWidth();
  useDocumentTitle('Boataround | Partner');
  useTracking();

  const { isAuthenticated } = useAuth();
  const { charter } = useCharter();
  const routes = useRoutes();

  const authorized = isAuthenticated && charter;
  const isIOSSafari = browserName === 'Safari' && osName === 'iOS';

  return (
    <div className="relative xl:flex bg-gray-100 min-h-screen overflow-x-hidden">
      {authorized && <Navigation />}
      <Suspense fallback={null}>
        <Switch>{routes}</Switch>
      </Suspense>
      <LocaleSettingsModal />
      <Toaster />
      {authorized && isIOSSafari && <PWAInstallPopupIOS />}
    </div>
  );
}

export default App;
