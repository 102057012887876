import { CharterAllBoatsList } from 'types/fleet';

export default function filterByCabins(
  boats: CharterAllBoatsList,
  cabins: [number, number]
) {
  return boats.filter(
    (boat) =>
      boat.cabins !== null &&
      boat.cabins >= cabins[0] &&
      boat.cabins <= cabins[1]
  );
}
