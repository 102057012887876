import { IconProps } from './IconProps';

interface FileIconProps extends IconProps {
  extension?: string;
}

const ICON_WIDTH = 28;
const TEXT_BOX_OFFSET = 7;
const MAX_EXTENSION_LENGTH = 4;

function pickColor(extension: string) {
  switch (extension) {
    case 'jpg':
      return '#c99718';
    case 'jpeg':
      return '#c99718';
    case 'png':
      return '#c99718';
    case 'webp':
      return '#c99718';
    case 'doc':
      return '#0d59a0';
    case 'docx':
      return '#0d59a0';
    case 'xls':
      return '#086823';
    case 'xlsx':
      return '#086823';
    case 'csv':
      return '#086823';
    case 'pdf':
      return '#ae090f';
    default:
      return '#4e576a';
  }
}

export default function IconFile(props: FileIconProps) {
  const { width: widthProp, extension: extensionProp } = props;

  const width = widthProp || ICON_WIDTH;
  const textPositionX = (width + TEXT_BOX_OFFSET) / 2;

  const extension =
    extensionProp && extensionProp.length <= MAX_EXTENSION_LENGTH
      ? extensionProp.toLowerCase()
      : '';

  const color = pickColor(extension);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="22"
      fill="none"
      viewBox="0 0 28 22"
      {...props}
    >
      <path
        fill="#D9D9D9"
        fillRule="evenodd"
        d="M0 7.406v12.05A2.544 2.544 0 0 0 2.544 22h14.898a2.544 2.544 0 0 0 2.543-2.544V2.544A2.544 2.544 0 0 0 17.442 0H7.406L0 7.406Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M0 7.406 7.406 0v4.863a2.544 2.544 0 0 1-2.543 2.543H0Z"
        clipRule="evenodd"
      />
      <rect
        width="21"
        height="11"
        x={TEXT_BOX_OFFSET}
        y="8"
        fill={color}
        rx="1.453"
        opacity={extension ? 100 : 0}
      />
      <text
        x={textPositionX}
        y="16"
        fontFamily="Open sans, sans-serif"
        fontSize="8"
        fill="white"
        textAnchor="middle"
      >
        {extension}
      </text>
    </svg>
  );
}
