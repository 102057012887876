import { QueryClient } from 'react-query';

export default function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // Disable refetch data when window focus is changed
        refetchOnWindowFocus: false,
      },
    },
  });
}
