import { DiscountsList } from 'types/discount';
import escapeString from 'utils/string/escapeString';

export default function filterByName(discounts: DiscountsList, name: string) {
  if (!name.trim().length) {
    return discounts;
  }

  const escapedName = escapeString(name);
  const regex = new RegExp(escapedName, 'i');
  return discounts.filter((discount) => regex.test(discount.name));
}
