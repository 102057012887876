import { IconProps } from './IconProps';

export default function IconPhone(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      viewBox="0 0 42 42"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6186 31.4244C16.6186 31.4244 19.2625 30.8956 25.079 25.079C30.8956 19.2625 31.4244 16.6186 31.4244 16.6186L27.1942 12.3883L34.5971 4.98539L42 12.3883C40.9374 15.2088 39.6102 17.959 38.0188 20.5997C35.9249 24.0745 33.3737 27.3599 30.3668 30.3668C27.3599 33.3737 24.0745 35.9249 20.5997 38.0188C17.959 39.6102 15.2088 40.9374 12.3883 42L4.98539 34.5971L12.3883 27.1942L16.6186 31.4244Z"
      />
      <path d="M21.1697 11.5752L21.6875 13.5079C17.4209 14.2589 14.0893 17.7227 13.5374 22.0546L11.5962 21.5345C12.35 16.5178 16.2184 12.5208 21.1697 11.5752Z" />
      <path d="M19.6182 5.78507L20.1351 7.71427C13.8133 9.04078 8.86831 14.1099 7.72477 20.4971L5.79207 19.9793C7.12662 12.9065 12.6107 7.29968 19.6182 5.78507Z" />
      <path d="M18.0681 0L18.5847 1.92805C10.2055 3.81631 3.64236 10.5 1.92996 18.9444L0 18.4273C1.89995 9.29688 9.00287 2.07424 18.0681 0Z" />
    </svg>
  );
}
