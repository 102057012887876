import { useCallback, useMemo, useState } from 'react';

export default function useElementRef<T>() {
  const [element, setElement] = useState<T | null>(null);

  const ref = useCallback((node: T) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  return useMemo(() => ({ element, ref }), [element, ref]);
}
