import { useContext } from 'react';
import { AvailabilitySettingsContext } from 'contexts/AvailabilitySettingsContext';

const useAvailabilitySettings = () => {
  const context = useContext(AvailabilitySettingsContext);

  if (context === undefined) {
    throw Error(
      'useAvailabilitySettings must be used within a AvailabilitySettingsProvider'
    );
  }

  return context;
};

export default useAvailabilitySettings;
