import {
  CharterOrdersParams,
  CharterOrdersResponse,
} from 'api/order/fetchOrdersByCharterId';
import updateOrderStatus from 'api/order/updateOrderStatus';
import updateCharterStatusInOrder from 'views/Order/OrdersTable/utils/updateCharterStatusInOrder';
import useCharter from 'hooks/useCharter/useCharter';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { CharterOrder, CharterOrderItem } from 'types/order';
import { ORDER, ORDERS, ORDERS_PENDING_COUNT } from 'utils/constants/queryKeys';
import useEventTracking from 'hooks/useEventTracking/useEventTracking';
import { useReactQuery } from 'hooks';

export type PendingOrderStatus = {
  order: CharterOrder | CharterOrderItem;
  action: 'approve' | 'decline';
};

export default function useOrderStatus(
  searchParams?: CharterOrdersParams,
  page?: number
) {
  const { t } = useTranslation();
  const { charter } = useCharter();
  const { createQueryKey, invalidateQuery } = useReactQuery();
  const queryClient = useQueryClient();
  const { trackEvent } = useEventTracking();

  const [pendingStatus, setPendingStatus] = useState<PendingOrderStatus | null>(
    null
  );

  const mutation = useMutation(updateOrderStatus, {
    onSuccess: (data, variables) => {
      setPendingStatus(null);

      // invalidate order queries or update only cached data for specific page
      const invalidate = !page;
      const rawOrdersQueryKey = invalidate
        ? ORDERS
        : [ORDERS, searchParams, page];

      if (invalidate) {
        invalidateQuery('localized', [ORDER, variables.orderId]);
        invalidateQuery('localized', rawOrdersQueryKey);
      } else {
        const ordersQueryKey = createQueryKey('localized', rawOrdersQueryKey);

        queryClient.setQueryData<CharterOrdersResponse | undefined>(
          ordersQueryKey,
          (oldOrders) => {
            if (oldOrders) {
              return updateCharterStatusInOrder(oldOrders, data, variables);
            }
          }
        );
      }

      invalidateQuery('general', ORDERS_PENDING_COUNT);
    },
    onError() {
      toast.error(t('Something went wrong. Please try again.'));
    },
  });

  const changeOrderStatus = useCallback(
    (message: string) => {
      if (!pendingStatus) {
        return;
      }

      trackEvent(
        pendingStatus.action === 'approve'
          ? 'cta_booking_approve'
          : 'cta_booking_decline'
      );

      mutation.mutate({
        charterId: charter?.id,
        orderId: pendingStatus.order.id,
        status: pendingStatus.action === 'approve' ? 'APPROVED' : 'DECLINED',
        message: message,
      });
    },
    [charter?.id, mutation, pendingStatus, trackEvent]
  );

  return useMemo(
    () => ({ changeOrderStatus, pendingStatus, setPendingStatus }),
    [changeOrderStatus, pendingStatus, setPendingStatus]
  );
}
