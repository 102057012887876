import { Locale } from 'i18n/types';

/**
 * Converts to non-standard underscore separator
 *
 * IETF BCP 47 defines as language and region separator hyphen (-)
 * Someone used underscore as separator in database/api, so it must be converted to this non-standard format
 * @link https://www.rfc-editor.org/rfc/rfc5646
 * @link https://en.wikipedia.org/wiki/IETF_language_tag
 */
export default function localeToUnderscore(locale: Locale): string {
  return locale.replace('-', '_');
}
