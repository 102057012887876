import { IconProps } from './IconProps';

export default function IconAnchor(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M6.43927 3.95478C7.27571 3.70833 7.88624 2.93467 7.88624 2.01841C7.88624 0.903671 6.98256 0 5.86783 0C4.75309 0 3.84942 0.903671 3.84942 2.01841C3.84942 2.93467 4.45996 3.70834 5.29641 3.95479V5.88746H2.63839V7.03032H5.29641L5.29641 14.7498C4.16843 14.674 3.09897 14.3677 2.28852 13.8536C2.10517 13.7372 1.93576 13.611 1.78193 13.4748L2.42209 13.1052L0 11.7068V14.5036L0.753244 14.0687C1.02485 14.3529 1.33639 14.603 1.67634 14.8186C2.84955 15.5628 4.3762 15.9138 5.86884 15.9113C7.36157 15.9087 8.88746 15.5525 10.0598 14.8075C10.3923 14.5962 10.6978 14.3521 10.9654 14.0755L11.7068 14.5035V11.7068L9.28467 13.1051L9.9347 13.4804C9.78592 13.6103 9.62282 13.7311 9.44681 13.8429C8.63605 14.3582 7.56667 14.6679 6.43927 14.7477L6.43927 7.03032H9.09729V5.88746H6.43927V3.95478ZM6.67519 2.01845C6.67519 2.46434 6.31372 2.82581 5.86783 2.82581C5.42194 2.82581 5.06047 2.46434 5.06047 2.01845C5.06047 1.57256 5.42194 1.21109 5.86783 1.21109C6.31372 1.21109 6.67519 1.57256 6.67519 2.01845Z" />
    </svg>
  );
}
