import { IconProps } from './IconProps';

export default function IconCheck(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      fill="currentColor"
      viewBox="0 -1 43 43"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M37.392 10.499l-19.161 19.5a1.5 1.5 0 01-2.278-.161L8.116 19.2l2.415-1.78 6.795 9.22L35.252 8.396l2.14 2.103z"
        clipRule="evenodd"
      />
    </svg>
  );
}
