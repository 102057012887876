import { useMemo, useReducer } from 'react';
import {
  availabilityFiltersReducer,
  initialAvailabilityFilters,
} from 'reducers/filters/availability';
import { CharterActiveBoatsList } from 'types/fleet';
import { filterBoats } from 'utils/filters';

export default function useAvailabilityFilters(boats?: CharterActiveBoatsList) {
  const [filters, dispatchFilters] = useReducer(
    availabilityFiltersReducer,
    initialAvailabilityFilters
  );

  const filteredBoats = useMemo(
    () =>
      boats
        ? (filterBoats(boats, {
            title: filters.boatTitle,
            marinas: filters.boatMarinas,
            cabins: filters.boatCabins,
          }) as CharterActiveBoatsList)
        : undefined,
    [boats, filters]
  );

  return useMemo(
    () => ({ filteredBoats, filters, changeFilters: dispatchFilters }),
    [filteredBoats, filters]
  );
}
