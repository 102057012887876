import { IconProps } from './IconProps';

export default function IconRemove(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width={41}
      viewBox="0 0 12 12"
      {...props}
    >
      <path d="M3.573 6.46756L3.8587 9.61032L4.7123 9.53272L4.4266 6.38996L3.573 6.46756Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28716 9.53272L7.57287 6.38996L8.42647 6.46756L8.14076 9.61032L7.28716 9.53272Z"
      />
      <path d="M5.57117 6.42874V9.57151H6.42829V6.42874H5.57117Z" />
      <path d="M10.4671 4.57166H1.53241L2.03086 10.3038C2.11431 11.2635 2.91768 12 3.88096 12H8.1185C9.08179 12 9.88516 11.2635 9.96861 10.3038L10.4671 4.57166ZM2.88475 10.2295L2.46729 5.42877H9.53217L9.11471 10.2295C9.06978 10.7463 8.63719 11.1429 8.1185 11.1429H3.88096C3.36227 11.1429 2.92969 10.7463 2.88475 10.2295Z" />
      <path d="M3.32031 1.28568C3.43942 0.813324 3.84634 0.503971 4.25432 0.322645C4.754 0.100567 5.38927 0 5.99976 0C6.61025 0 7.24552 0.100565 7.7452 0.322643C8.15318 0.50397 8.5601 0.813323 8.67921 1.28568H9.71393C10.5029 1.28568 11.1425 1.92525 11.1425 2.7142V3.85703H0.857056V2.7142C0.857056 1.92525 1.49663 1.28568 2.28558 1.28568H3.32031ZM4.30167 1.28568H7.69786C7.62842 1.22636 7.53123 1.16551 7.39709 1.10589C7.03964 0.947022 6.5321 0.857117 5.99976 0.857117C5.46742 0.857118 4.95988 0.947024 4.60243 1.10589C4.46829 1.16551 4.3711 1.22636 4.30167 1.28568ZM1.71417 2.7142V2.99991H10.2853V2.7142C10.2853 2.39862 10.0295 2.14279 9.71393 2.14279H2.28558C1.97 2.14279 1.71417 2.39862 1.71417 2.7142Z" />
    </svg>
  );
}
