import { IconProps } from './IconProps';

export default function IconLocationFilled(props: IconProps) {
  return (
    <svg
      viewBox="0 0 46 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.2929 66.707L23 67.4141L23.7071 66.707C27.2357 63.1784 32.4331 55.7055 36.7399 47.6134C38.8994 43.5559 40.8508 39.3134 42.2646 35.2979C43.6736 31.2959 44.5714 27.4564 44.5714 24.2245C44.5714 11.4721 34.9849 1 23 1C11.015 1 1.42856 11.4721 1.42856 24.2245C1.42856 27.4564 2.32631 31.2959 3.73531 35.2979C5.14911 39.3134 7.10055 43.5559 9.26008 47.6134C13.5668 55.7055 18.7643 63.1784 22.2929 66.707Z"
        fill="currentColor"
        stroke="#fff"
        strokeWidth="2"
      />
      <circle cx="23" cy="22" r="10" fill="#fff" />
    </svg>
  );
}
