import { useLayoutEffect } from 'react';
import useScrollbarSize from 'react-scrollbar-size';
import setGlobalStylesVariable from 'utils/setGlobalStylesVariable';

export default function useScrollbarWidth() {
  const { width } = useScrollbarSize();

  useLayoutEffect(() => {
    setGlobalStylesVariable('scrollbar-width', width + 'px');
  }, [width]);
}
