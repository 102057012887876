import dayjs from 'dayjs';
import { Locale } from 'i18n/types';
import { languageCode } from 'utils/i18n';

const getLocaleForDayjs = (locale: Locale) => {
  const lang = languageCode(locale);
  // https://github.com/iamkun/dayjs/tree/dev/src/locale
  return lang === 'en' ? 'en-gb' : lang;
};

export default async function changeDayjsLocale(locale: Locale) {
  const dayjsLocale = getLocaleForDayjs(locale);

  await import(`dayjs/locale/${dayjsLocale}.js`);
  dayjs.locale(dayjsLocale);
}
