import { IconProps } from './IconProps';

export default function IconEdit(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width={41}
      viewBox="0 0 12 12"
      {...props}
    >
      <path d="M9.28441 1.10798L10.8916 2.71513C11.2263 3.04986 11.2263 3.59258 10.8916 3.92731L4.46298 10.3559C4.35313 10.4657 4.21549 10.5437 4.06478 10.5813L1.92192 11.1171C1.62983 11.1901 1.32084 11.1045 1.10794 10.8916C0.895045 10.6787 0.80946 10.3697 0.882483 10.0776L1.4182 7.93476C1.45588 7.78405 1.53381 7.64641 1.64366 7.53655L8.07223 1.10798C8.40696 0.77325 8.94967 0.77325 9.28441 1.10798ZM8.67832 1.71408L7.42779 2.9646L9.03493 4.57174L10.2855 3.32122L8.67832 1.71408ZM8.42884 5.17784L6.8217 3.57069L2.24975 8.14265L1.71403 10.2855L3.85689 9.74979L8.42884 5.17784Z" />
    </svg>
  );
}
