import { CharterOrdersResponse } from 'api/order/fetchOrdersByCharterId';
import {
  UpdateOrderStatusData,
  UpdateOrderStatusResponse,
} from 'api/order/updateOrderStatus';
import { CharterOrderItem } from 'types/order';

export default function updateCharterStatusInOrder(
  orders: CharterOrdersResponse,
  mutationData: UpdateOrderStatusResponse,
  mutationVariables: UpdateOrderStatusData
): CharterOrdersResponse {
  return {
    count: orders.count,
    data: orders.data.map(
      (order): CharterOrderItem =>
        order.id === mutationVariables.orderId
          ? {
              ...order,
              booking_status: mutationData.status,
              charter_status: mutationVariables.status,
            }
          : order
    ),
  };
}
