import { IconProps } from './IconProps';

export default function IconDownload(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <path d="M12.5712 1.14283C12.7816 1.14283 12.9521 1.31339 12.9521 1.52378V12.7019C12.9521 12.8029 12.912 12.8998 12.8405 12.9713L11.0665 14.7453C10.9951 14.8167 10.8982 14.8568 10.7972 14.8568H3.4285C3.21811 14.8568 3.04756 14.6863 3.04756 14.4759V1.52378C3.04756 1.31339 3.21811 1.14283 3.4285 1.14283H12.5712ZM3.4285 0C2.58694 0 1.90472 0.682219 1.90472 1.52378V14.4759C1.90472 15.3175 2.58694 15.9997 3.4285 15.9997H10.7972C11.2013 15.9997 11.5889 15.8391 11.8746 15.5534L13.6487 13.7794C13.9344 13.4936 14.095 13.106 14.095 12.7019V1.52378C14.095 0.682219 13.4127 0 12.5712 0H3.4285Z" />
      <path d="M10.6661 8.95192L8.40358 12.0226C8.18042 12.2457 7.81862 12.2457 7.59547 12.0226L5.33322 8.95192L7.42811 8.95192L7.4281 3.80916L8.57094 3.80916L8.57125 8.95192L10.6661 8.95192Z" />
    </svg>
  );
}
