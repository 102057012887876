import { useLayoutEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';

const currentSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export default function useWindowSize() {
  const [size, setSize] = useState(currentSize());

  const callback = useMemo(() => {
    return debounce(16, () => {
      setSize(currentSize());
    });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback]);

  return size;
}
