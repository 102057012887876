import { IconProps } from './IconProps';

export default function IconRevenue(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      viewBox="0 0 38 40"
      fill="currentColor"
      {...props}
    >
      <path d="M0.380371 2.75862C0.380371 1.23508 1.61545 0 3.13899 0H26.5873C28.1108 0 29.3459 1.23508 29.3459 2.75862V18.0068C28.893 17.9567 28.4328 17.931 27.9666 17.931C27.5004 17.931 27.0401 17.9567 26.5873 18.0068V2.75862H3.13899V34.023H16.1067C16.4093 34.9996 16.8291 35.9246 17.3498 36.7816H3.13899C1.61545 36.7816 0.380371 35.5465 0.380371 34.023V2.75862Z" />
      <path d="M7.04703 11.954H22.6792V9.19535H7.04703V11.954Z" />
      <path d="M22.6792 19.1101C22.2598 19.3078 21.8533 19.5284 21.4612 19.7701H7.04703V17.0115H22.6792V19.1101Z" />
      <path d="M16.8432 24.8275C16.4124 25.6944 16.0799 26.6189 15.8604 27.5862H7.04703V24.8275H16.8432Z" />
      <path d="M27.9666 40C22.6342 40 18.3114 35.6772 18.3114 30.3448C18.3114 25.0124 22.6342 20.6897 27.9666 20.6897C33.299 20.6897 37.6217 25.0124 37.6217 30.3448C37.6217 35.6772 33.299 40 27.9666 40ZM26.9679 31.879H29.3501V30.7874H26.9679V29.858H29.3501V28.7737H26.9817C27.0373 27.9771 27.2549 27.3993 27.6346 27.0404C28.0143 26.6814 28.5236 26.5019 29.1625 26.5019C29.394 26.5019 29.6255 26.5241 29.857 26.5683C30.0886 26.6126 30.3039 26.6691 30.503 26.738L30.7252 25.0562C30.452 24.9874 30.1788 24.9333 29.9057 24.894C29.6371 24.8497 29.3616 24.8276 29.0792 24.8276C28.5097 24.8276 27.9842 24.9112 27.5026 25.0784C27.0257 25.2456 26.609 25.4963 26.2525 25.8307C25.9006 26.1602 25.6182 26.5708 25.4052 27.0625C25.1922 27.5542 25.0672 28.1247 25.0302 28.7737H23.8286V29.858H25.0163V30.7874H23.8286V31.879H25.0163C25.0348 32.538 25.1436 33.1182 25.3427 33.6198C25.5464 34.1164 25.8219 34.5319 26.1692 34.8663C26.5211 35.1958 26.9331 35.4441 27.4054 35.6113C27.8823 35.7785 28.4032 35.8621 28.9681 35.8621C29.2737 35.8621 29.5746 35.8424 29.8709 35.8031C30.1673 35.7637 30.452 35.7096 30.7252 35.6408L30.503 33.9664C30.2946 34.05 30.0747 34.1115 29.8432 34.1508C29.6163 34.1853 29.3802 34.2025 29.1348 34.2025C28.8153 34.2025 28.5259 34.1631 28.2666 34.0845C28.0073 34.0009 27.7828 33.8681 27.5929 33.6861C27.4077 33.5042 27.2596 33.2657 27.1484 32.9707C27.0419 32.6707 26.9817 32.3068 26.9679 31.879Z" />
    </svg>
  );
}
