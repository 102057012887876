import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { useRoute } from 'hooks';

type LinkProps = RouterLinkProps & {
  to: string;
  params?: object;
};

export default function Link({ to, params = {}, ...rest }: LinkProps) {
  const route = useRoute(to, params);

  return (
    <RouterLink {...rest} to={route}>
      {rest.children}
    </RouterLink>
  );
}
