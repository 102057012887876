import { IconProps } from './IconProps';

export default function IconArrowLeft(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42 42"
      width={42}
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M.415 19.964L17 2.487l2.17 2.071L5.005 19.5H42v3H5.005L19.17 37.441 17 39.514.415 22.036a1.5 1.5 0 010-2.072z"
        clipRule="evenodd"
      />
    </svg>
  );
}
