export default function IconContactUs() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={88}
      fill="none"
      viewBox="0 0 64 64"
    >
      <circle cx="32" cy="32" r="32" fill="#F0F4FF" />
      <path
        fill="url(#paint0_linear_1514_33260)"
        fillOpacity=".2"
        d="M14.692 28.24l17.255 19.643 17.36-19.763-8.059-9.175h-18.39l-8.166 9.295z"
      />
      <path
        fill="url(#paint1_linear_1514_33260)"
        fillRule="evenodd"
        d="M31.924 55.941L57 27.298 45.359 14H18.793L7 27.471l24.924 28.47zm0-2.53l22.86-26.113-10.181-11.631H19.55L9.215 27.47l22.709 25.94z"
        clipRule="evenodd"
      />
      <path
        fill="url(#paint2_linear_1514_33260)"
        d="M35.509 32.238a5.497 5.497 0 011.753 3.793c.004.081.005 3.25.005 3.332H26.765v.429h11.023c0-.082-.001-3.251-.005-3.332a5.505 5.505 0 00-2.274-4.222z"
      />
      <path
        fill="url(#paint3_linear_1514_33260)"
        d="M20.172 37.844c-.014.141-.02.284-.02.429h5.435c-.192-2.242.534-4.126.534-4.126a4.374 4.374 0 00-.606-.18c-.186.582-.6 2.12-.45 3.877h-4.893z"
      />
      <path
        fill="url(#paint4_linear_1514_33260)"
        d="M38.937 37.56a8.806 8.806 0 01-.027.429h5.436a4.404 4.404 0 00-1.879-3.612 4.397 4.397 0 011.358 3.183h-4.889z"
      />
      <path
        fill="url(#paint5_linear_1514_33260)"
        d="M31.763 30.765a5.512 5.512 0 015.506 5.268c.004.08.006 3.25.006 3.332H26.25c0-.082.002-3.251.006-3.332a5.512 5.512 0 015.506-5.268z"
      />
      <path
        fill="url(#paint6_linear_1514_33260)"
        d="M25.607 33.805a4.41 4.41 0 00-5.97 4.125h5.436c-.192-2.241.534-4.125.534-4.125z"
      />
      <path
        fill="url(#paint7_linear_1514_33260)"
        d="M37.862 33.52a4.41 4.41 0 015.97 4.125l-5.435.001c.192-2.241-.535-4.125-.535-4.125z"
      />
      <g>
        <path
          fill="url(#paint8_linear_1514_33260)"
          d="M31.246 28.546a3.773 3.773 0 003.185-5.797 3.773 3.773 0 11-5.81 4.733 3.76 3.76 0 002.625 1.064z"
        />
        <path
          fill="url(#paint9_linear_1514_33260)"
          d="M35.064 24.773a3.773 3.773 0 11-7.546 0 3.773 3.773 0 017.546 0z"
        />
      </g>
      <g>
        <path
          fill="url(#paint10_linear_1514_33260)"
          d="M39.041 31.432a2.646 2.646 0 002.234-4.066 2.646 2.646 0 11-4.076 3.32 2.637 2.637 0 001.842.746z"
        />
        <path
          fill="url(#paint11_linear_1514_33260)"
          d="M41.719 28.786a2.646 2.646 0 11-5.293 0 2.646 2.646 0 015.293 0z"
        />
      </g>
      <g>
        <path
          fill="url(#paint12_linear_1514_33260)"
          d="M23.623 31.604a2.647 2.647 0 002.233-4.066 2.646 2.646 0 11-4.076 3.32 2.638 2.638 0 001.843.746z"
        />
        <path
          fill="url(#paint13_linear_1514_33260)"
          d="M26.3 28.957a2.647 2.647 0 11-5.292 0 2.647 2.647 0 015.293 0z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1514_33260"
          x1="19.912"
          x2="45.828"
          y1="18.945"
          y2="44.769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".09" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1514_33260"
          x1="7"
          x2="54.917"
          y1="2.917"
          y2="46.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".09" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1514_33260"
          x1="41.308"
          x2="26.233"
          y1="28.88"
          y2="41.386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#2F7CC5" stopOpacity=".15" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1514_33260"
          x1="41.308"
          x2="26.233"
          y1="28.88"
          y2="41.386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#2F7CC5" stopOpacity=".15" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1514_33260"
          x1="41.308"
          x2="26.233"
          y1="28.88"
          y2="41.386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#2F7CC5" stopOpacity=".15" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1514_33260"
          x1="19.612"
          x2="39.647"
          y1="25.585"
          y2="41.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1514_33260"
          x1="19.612"
          x2="39.647"
          y1="25.585"
          y2="41.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1514_33260"
          x1="19.612"
          x2="39.647"
          y1="25.585"
          y2="41.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1514_33260"
          x1="37.626"
          x2="27.775"
          y1="21.343"
          y2="27.082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1514_33260"
          x1="27.518"
          x2="33.312"
          y1="19.787"
          y2="30.096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1514_33260"
          x1="43.516"
          x2="36.606"
          y1="26.38"
          y2="30.405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#2F7CC5" stopOpacity=".15" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1514_33260"
          x1="36.426"
          x2="40.49"
          y1="25.289"
          y2="32.519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1514_33260"
          x1="28.097"
          x2="21.188"
          y1="26.551"
          y2="30.577"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#2F7CC5" stopOpacity=".15" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1514_33260"
          x1="21.008"
          x2="25.072"
          y1="25.46"
          y2="32.691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1576D1" />
          <stop offset="1" stopColor="#1576D1" stopOpacity=".15" />
        </linearGradient>
      </defs>
    </svg>
  );
}
