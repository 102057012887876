import { useEffect, useState } from 'react';
import usePWAInstallPrompt from 'hooks/usePWAInstallPrompt/usePWAInstallPrompt';
import { t } from 'i18next';
import { IconShareIOS } from 'components/icons';
import classNames from 'classnames';

const ICON_HEIGHT = 20;

type PWAInstallSidebarButtonProps = {
  buttonStyle?: string;
};

function PWAInstallSidebarButton({
  buttonStyle,
}: PWAInstallSidebarButtonProps) {
  const [prompt, promptToInstall] = usePWAInstallPrompt();
  const [isVisible, toggleVisible] = useState(false);

  const userAgent = window.navigator.userAgent.toLowerCase();
  const isFirefox = userAgent.includes('firefox');
  const isSafari =
    userAgent.includes('safari') && !userAgent.includes('chrome');

  const handleClick = () => {
    promptToInstall();
    toggleVisible(false);
  };

  useEffect(() => {
    if (prompt) {
      toggleVisible(true);
    }
  }, [prompt]);

  if (isFirefox || isSafari) {
    return null;
  }

  return (
    <li
      className={classNames({
        invisible: !isVisible,
      })}
    >
      <button onClick={handleClick} className={buttonStyle}>
        <IconShareIOS className="mr-3" height={ICON_HEIGHT} />
        {t('Add to home screen')}
      </button>
    </li>
  );
}

export default PWAInstallSidebarButton;
