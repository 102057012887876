import { ReactNode, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { useBreakpoint, useClickOutside } from 'hooks';
import { Modal } from 'components/modals';
import { twMerge } from 'tailwind-merge';
import { ModalProps } from 'components/modals/Modal/Modal';

type PositionTop = 'top-left' | 'top-center' | 'top-right';
type PositionRight = 'right-bottom' | 'right-center' | 'right-top';
type PositionBottom = 'bottom-left' | 'bottom-center' | 'bottom-right';
type PositionLeft = 'left-bottom' | 'left-center' | 'left-top';

export type DropdownProps = {
  items: (close: () => void) => ReactNode; // pass close functionality to children
  position?: PositionTop | PositionRight | PositionBottom | PositionLeft;
  disabled?: boolean;
  ariaLabel?: string;
  highlightedActiveButton?: boolean;
  buttonClassName?: string;
  modalProps?: Partial<ModalProps>;
  children: ReactNode;
};

export default function Dropdown({
  items,
  position = 'bottom-left',
  disabled,
  ariaLabel,
  highlightedActiveButton = true,
  buttonClassName,
  modalProps,
  children,
}: DropdownProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const { isBreakpointTo } = useBreakpoint();
  const smallDevice = isBreakpointTo('sm');

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const close = useCallback(() => setOpen(false), []);

  const handleClickOutside = useCallback(() => {
    if (!smallDevice) {
      setOpen(false);
    }
  }, [smallDevice]);

  useClickOutside(dropdownRef, handleClickOutside);

  const primaryPosition = position.split('-')[0];

  const buttonRefWidth = buttonRef.current?.clientWidth || 0;
  const buttonRefHeight = buttonRef.current?.clientHeight || 0;

  const buttonWidth = `${buttonRefWidth}px`;
  const buttonHeight = `${buttonRefHeight}px`;
  const distanceX = `calc(-50% + ${Math.round(buttonRefWidth / 2)}px)`;
  const distanceY = `calc(-50% - ${Math.round(buttonRefHeight / 2)}px)`;

  const positionStyles = {
    'top-left': { bottom: buttonHeight, right: 0 },
    'top-center': {
      bottom: buttonHeight,
      transform: `translateX(${distanceX})`,
    },
    'top-right': { bottom: buttonHeight, left: 0 },
    'right-bottom': { top: 0, left: buttonWidth },
    'right-center': {
      transform: `translateY(${distanceY})`,
      left: buttonWidth,
    },
    'right-top': { bottom: 0, left: buttonWidth },
    'bottom-left': { top: buttonHeight, right: 0 },
    'bottom-center': {
      top: buttonHeight,
      transform: `translateX(${distanceX})`,
    },
    'bottom-right': { top: buttonHeight, left: 0 },
    'left-bottom': { top: 0, right: buttonWidth },
    'left-center': {
      transform: `translateY(${distanceY})`,
      right: buttonWidth,
    },
    'left-top': { bottom: 0, right: buttonWidth },
  };

  return (
    <div ref={dropdownRef} className="relative inline-block leading-none">
      {/* Dropdown toggle button */}
      <button
        ref={buttonRef}
        type="button"
        aria-label={ariaLabel}
        onClick={handleClick}
        className={twMerge(
          classNames({
            'pointer-events-none opacity-30': disabled,
            'bg-gray-100': highlightedActiveButton && open,
          }),

          buttonClassName
        )}
        disabled={disabled}
      >
        {children}
      </button>

      {/* Dropdown menu */}
      {open &&
        (smallDevice ? (
          <Modal {...modalProps} isOpen={open} onRequestClose={close}>
            <div className="pb-5">{items(close)}</div>
          </Modal>
        ) : (
          <div
            className={classNames(
              {
                'hidden': !open,
                'mb-2 shadow-t-2xl': primaryPosition === 'top',
                'ml-2 shadow-r-2xl': primaryPosition === 'right',
                'mt-2 shadow-b-2xl': primaryPosition === 'bottom',
                'mr-2 shadow-l-2xl': primaryPosition === 'left',
              },
              'w-max max-w-sm absolute z-20 bg-white rounded-md overflow-hidden'
            )}
            style={positionStyles[position]}
          >
            {items(close)}
          </div>
        ))}
    </div>
  );
}
