import { IconProps } from './IconProps';

export default function IconChevronRight(props: IconProps) {
  return (
    <svg
      width={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 42"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M32.32 22.44L16.94 37.82l-2.121-2.121 14.319-14.32-14.32-14.318 2.122-2.122 15.38 15.38a1.5 1.5 0 010 2.121z"
        clipRule="evenodd"
      />
    </svg>
  );
}
