import axios from 'axios';
import { BOATAROUND_WEB_URL } from 'utils/constants/environment';

type RefreshJwtTokenResponse = {
  accessToken: string;
};

const refreshJwtToken = async () => {
  const { data } = await axios.get<RefreshJwtTokenResponse>(
    `/account/refresh-jwt`,
    {
      baseURL: BOATAROUND_WEB_URL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },
      // Do not throw and log error when refreshing token is not successful
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 401,
    }
  );

  return data.accessToken;
};

export default refreshJwtToken;
