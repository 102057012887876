import { Route, RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ROUTE_LOGIN } from 'utils/constants/routes';
import { Redirect } from 'components/routing';
import { useAuth } from 'hooks';
import { useRef } from 'react';

type PrivateRouteProps = RouteProps;

export default function PrivateRoute({ render, ...rest }: PrivateRouteProps) {
  const { isAuthenticated } = useAuth();
  const initialIsAuthenticated = useRef(isAuthenticated);

  const { pathname } = useLocation();

  let redirectState = {};
  // If user logouts, referrer mustn't be set
  if (initialIsAuthenticated.current === isAuthenticated) {
    redirectState = { referrer: pathname };
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          render && render(props)
        ) : (
          <Redirect to={ROUTE_LOGIN} state={redirectState} />
        );
      }}
    />
  );
}
