import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Sidebar from 'components/layout/sidebar/Sidebar/Sidebar';
import logomark from 'assets/logomark.svg';
import { Link } from 'components/routing';
import { useLocation } from 'react-router';
import Button from 'components/Button/Button';
import { IconArrowLeft, IconBurger } from 'components/icons';
import { ROUTE_DASHBOARD } from 'utils/constants/routes';
import { useRouteBack } from 'hooks';

export default function Navigation() {
  const location = useLocation();
  const history = useHistory();
  const routeBack = useRouteBack();

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    // Auto close Sidebar when route is changed (click on link in navigation)
    setOpened(false);
  }, [location]);

  return (
    <div className="z-50">
      <Sidebar opened={opened} onClose={() => setOpened(false)} />

      {/* Mobile top navigation */}
      <div className="xl:hidden">
        <header className="print:hidden flex justify-between space-x-3 p-4 pt-3 md:py-4 xl:p-6">
          {routeBack ? (
            <Button
              size="normal"
              showAsIcon
              onClick={() => history.push(routeBack)}
            >
              <IconArrowLeft width={20} className="fill-gray-700" />
            </Button>
          ) : (
            <Link to={ROUTE_DASHBOARD} className="xl:hidden">
              <img className="w-7" src={logomark} alt="" />
            </Link>
          )}
          <Button size="small" showAsIcon onClick={() => setOpened(true)}>
            <IconBurger width={30} />
          </Button>
        </header>
      </div>
    </div>
  );
}
