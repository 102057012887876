import { useContext } from 'react';
import { Modal } from 'components/modals';
import locales from 'i18n/localeTitles';
import { Locale } from 'i18n/types';
import { useLocale } from 'hooks';
import { GlobalContext } from 'contexts/GlobalContext';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function LocaleSettingsModal() {
  const { t } = useTranslation();
  const { locale: currentLocale, setLocale } = useLocale();

  const { localeSettingsShown, toggleLocaleSettingsModal } =
    useContext(GlobalContext);

  const handleClick = (locale: Locale) => {
    toggleLocaleSettingsModal();
    setLocale(locale);
  };

  return (
    <Modal
      title={t('Choose a language')}
      isOpen={localeSettingsShown}
      onRequestClose={toggleLocaleSettingsModal}
    >
      <ul className="grid grid-cols-2 sm:grid-cols-3 gap-3 px-3 md:px-4 pt-1 pb-3 md:pb-4 text-sm text-gray-600">
        {Object.entries(locales).map(([locale, title]) => {
          return (
            <li key={locale}>
              <Button
                variant="link"
                ring={locale === currentLocale}
                fullWidth
                disableUppercase
                onClick={() => handleClick(locale as Locale)}
              >
                {title}
              </Button>
            </li>
          );
        })}
      </ul>
    </Modal>
  );
}
