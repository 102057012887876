import axios from 'axios';
import { API_BASE_URL } from 'utils/constants/environment';
import {
  authErrorResponseInterceptor,
  authRequestInterceptor,
} from 'utils/axios/interceptors';
import { createURLSearchParams } from './http';

// There should be global axios settings like interceptors, defaults, auth token, etc.
export default function setupAxios() {
  axios.defaults.baseURL = API_BASE_URL;

  // Use URLSearchParams for skipping empty/undefined params and encoding the rest
  axios.defaults.paramsSerializer = createURLSearchParams;

  // Auth Token Interceptors
  axios.interceptors.request.use(authRequestInterceptor);
  axios.interceptors.response.use(
    (response) => response, // Just return the response if everything is fine (status code 200 - 299)
    authErrorResponseInterceptor
  );
}
