import { IconProps } from './IconProps';

export default function IconPlusIOS(props: IconProps) {
  return (
    <svg
      width={30}
      fill="currentColor"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 4a4 4 0 0 1 4-4h22a4 4 0 0 1 4 4v22a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Zm4-2a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4Z" />
      <path d="M16 8a1 1 0 1 0-2 0v6H8a1 1 0 1 0 0 2h6v6.5a1 1 0 1 0 2 0V16h6.5a1 1 0 1 0 0-2H16V8Z" />
    </svg>
  );
}
