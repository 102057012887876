import { LanguageCode } from 'i18n/types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import isValidPathLocale from 'utils/i18n/isValidPathLocale';
import languageCode from 'utils/i18n/languageCode';
import trimLeadingSlash from 'utils/trimLeadingSlash';
import { useLocale } from 'hooks';

/**
 * Sync URL with current locale
 */
export default function useRouteLocaleResolver() {
  const { locale } = useLocale();
  const history = useHistory();

  const pathname = history.location.pathname;

  useEffect(() => {
    const { pathname } = history.location;

    const pathLocale = trimLeadingSlash(pathname).substr(0, 2) as LanguageCode;
    const lang = languageCode(locale);

    if (pathLocale !== lang) {
      let path;
      if (isValidPathLocale(pathLocale)) {
        // replace current locale with new one
        path = '/' + lang + trimLeadingSlash(pathname).substring(2);
        history.push(path);
      } else {
        // add locale to url which is without locale
        path = '/' + lang + (pathname === '/' ? '' : pathname);
        history.replace(path);
      }
    }
  }, [locale, history, pathname]);
}
