import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import { useRoute } from 'hooks';

type NavLinkProps = RouterNavLinkProps & {
  to: string;
  params?: object;
};

export default function NavLink({ to, params = {}, ...rest }: NavLinkProps) {
  const route = useRoute(to, params);

  return (
    <RouterNavLink {...rest} to={route}>
      {rest.children}
    </RouterNavLink>
  );
}
