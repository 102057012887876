import { createContext, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import { Locale } from 'i18n/types';
import { changeDayjsLocale, detectLocaleFromPath } from 'utils/i18n';

type LocaleState = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};

type LocaleProviderProps = {
  children: React.ReactNode;
  forcedLocale?: Locale;
};

const LocaleContext = createContext<LocaleState | undefined>(undefined);

let globalLocale: Locale;

function LocaleProvider({ children, forcedLocale }: LocaleProviderProps) {
  const [locale, setLocale] = useState(forcedLocale || detectLocaleFromPath());

  useEffect(() => {
    // https://github.com/facebook/react/issues/14326#issuecomment-441680293
    (async () => {
      // Save outside context to be able use it outside components (eg. http requests)
      globalLocale = locale;

      document.documentElement.lang = locale; // Set <html lang> attribute

      // Firstly load locale for dayjs (it's not reactive),
      // App will be rerendered by i18next below
      await changeDayjsLocale(locale);

      i18n.changeLanguage(locale);
    })();
  }, [locale]);

  const value = useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale]
  );

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

function getLocale() {
  return globalLocale;
}

export { LocaleContext, LocaleProvider, getLocale };
