const BOATAROUND_WEB_URL = (
  process.env.REACT_APP_BOATAROUND_WEB_URL || ''
).replace(/\/$/, '');
const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

if (!BOATAROUND_WEB_URL) {
  throw Error('REACT_APP_BOATAROUND_WEB_URL is not set');
}

if (!API_BASE_URL) {
  throw Error('REACT_APP_BASE_API_URL is not defined');
}

export { BOATAROUND_WEB_URL, API_BASE_URL, GOOGLE_MAPS_API_KEY };
