import { IconProps } from './IconProps';

export default function IconAccountCircled(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44}
      viewBox="0 0 44 43"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M22.224 27.186c4.97 0 9-4.083 9-9.12 0-5.037-4.03-9.12-9-9.12-4.971 0-9 4.083-9 9.12 0 5.037 4.029 9.12 9 9.12zm0-3.04c-3.314 0-6-2.722-6-6.08s2.686-6.08 6-6.08c3.313 0 6 2.722 6 6.08s-2.687 6.08-6 6.08z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M22.224 42.946c-11.598 0-21-9.402-21-21s9.402-21 21-21c11.597 0 21 9.402 21 21s-9.403 21-21 21zm12.436-7.988a17.948 17.948 0 005.563-13.012c0-9.94-8.059-18-18-18-9.94 0-18 8.06-18 18 0 5.117 2.136 9.735 5.564 13.012 3.024-3.513 7.475-5.732 12.437-5.732 4.961 0 9.412 2.22 12.436 5.732zm-2.348 1.897c-2.473-2.816-6.076-4.59-10.088-4.59-4.013 0-7.616 1.774-10.089 4.59a17.917 17.917 0 0010.089 3.09c3.738 0 7.21-1.139 10.088-3.09z"
        clipRule="evenodd"
      />
    </svg>
  );
}
