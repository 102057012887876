import {
  Redirect as RouterRedirect,
  RedirectProps as RouterRedirectProps,
} from 'react-router-dom';
import { useRoute } from 'hooks';

type RedirectProps = RouterRedirectProps & {
  to: string;
  state?: object;
  params?: object;
};

export default function Redirect({
  to,
  params = {},
  state = {},
  ...rest
}: RedirectProps) {
  const route = useRoute(to, params);

  return <RouterRedirect {...rest} to={{ pathname: route, state }} />;
}
