import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routesFactory from 'routes';

export default function useCurrentRoute() {
  const location = useLocation();
  const { t } = useTranslation();

  return useMemo(() => {
    return routesFactory(t).find(({ path, exact }) =>
      matchPath(location.pathname, { path, exact })
    );
  }, [location, t]);
}
