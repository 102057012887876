import { Route } from 'routes';
import trimLeadingSlash from 'utils/trimLeadingSlash';

export default function localizeRoutes(routes: Route[]): Route[] {
  return routes.map(({ path, ...rest }: Route) => {
    return {
      ...rest,
      path: `/:locale([a-z]{2})/${trimLeadingSlash(path)}`,
    };
  });
}
