import { IconProps } from './IconProps';

export default function IconAccount(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      viewBox="0 0 16 17"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8571 4.14291C11.8571 6.27315 10.1302 8.00005 7.99999 8.00005C5.86975 8.00005 4.14285 6.27315 4.14285 4.14291C4.14285 2.01267 5.86975 0.285767 7.99999 0.285767C10.1302 0.285767 11.8571 2.01267 11.8571 4.14291ZM10.5714 4.14291C10.5714 2.72275 9.42015 1.57148 7.99999 1.57148C6.57983 1.57148 5.42856 2.72275 5.42856 4.14291C5.42856 5.56307 6.57983 6.71434 7.99999 6.71434C9.42015 6.71434 10.5714 5.56307 10.5714 4.14291Z"
      />
      <path d="M2.21428 16.3572C2.21428 13.1618 4.80463 10.5715 7.99999 10.5715C11.1954 10.5715 13.7857 13.1618 13.7857 16.3572H15.0714C15.0714 12.4518 11.9054 9.28577 7.99999 9.28577C4.09455 9.28577 0.928566 12.4518 0.928566 16.3572H2.21428Z" />
    </svg>
  );
}
