import { IconProps } from './IconProps';

export default function IconX(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      fill="currentColor"
      viewBox="0 0 43 42"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M18.94 20.56L8 31.5l2.121 2.121 10.94-10.939L32 33.622l2.121-2.122-10.939-10.94 10.94-10.939L32 7.5 21.06 18.44 10.122 7.5 8 9.621l10.94 10.94z"
        clipRule="evenodd"
      />
    </svg>
  );
}
