import { IconProps } from './IconProps';

export default function IconUpload(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      viewBox="0 0 32 32"
      fill="currentColor"
      {...props}
    >
      <path d="M25.1426 29.7152C25.5634 29.7152 25.9045 29.3741 25.9045 28.9533V6.59681C25.9045 6.39474 25.8242 6.20095 25.6813 6.05807L22.1333 2.51003C21.9904 2.36714 21.7966 2.28687 21.5946 2.28687H6.85707C6.43629 2.28687 6.09518 2.62798 6.09518 3.04877V28.9533C6.09518 29.3741 6.43629 29.7152 6.85707 29.7152H25.1426ZM6.85707 32.0009C5.17394 32.0009 3.80949 30.6364 3.80949 28.9533V3.04877C3.80949 1.36563 5.17394 0.00117934 6.85707 0.00117934H21.5946C22.4028 0.00117934 23.178 0.322265 23.7495 0.893798L27.2976 4.44184C27.8691 5.01337 28.1902 5.78854 28.1902 6.59681V28.9533C28.1902 30.6364 26.8257 32.0009 25.1426 32.0009H6.85707Z" />
      <path d="M21.3325 14.0964L16.8073 7.95497C16.361 7.50866 15.6374 7.50866 15.1911 7.95497L10.6666 14.0964L14.8564 14.0964L14.8564 24.382L17.1421 24.382L17.1427 14.0964L21.3325 14.0964Z" />
    </svg>
  );
}
