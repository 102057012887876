import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Actions = 'push' | 'replace';

export default function useUrlSearchParams() {
  const history = useHistory();
  const { search } = useLocation();

  const setSearchParam = useCallback(
    (key: string, value: string, action: Actions = 'push') => {
      const oldParams = new URLSearchParams(search);
      oldParams.set(key, value);

      if (action === 'push') {
        history.push({ search: oldParams.toString() });
      } else {
        history.replace({ search: oldParams.toString() });
      }
    },
    [history, search]
  );

  const resetSearchParams = useCallback(
    (params?: string | Record<string, string>, action: Actions = 'push') => {
      const newParams = params ? new URLSearchParams(params).toString() : '';

      if (action === 'push') {
        history.push({ search: newParams });
      } else {
        history.replace({ search: newParams });
      }
    },
    [history]
  );

  return useMemo(
    () => ({
      searchParams: new URLSearchParams(search),
      setSearchParam: setSearchParam,
      resetSearchParams: resetSearchParams,
    }),
    [search, setSearchParam, resetSearchParams]
  );
}
