const locales = [
  'en-GB',
  'sk-SK',
  'cs-CZ',
  'ru-RU',
  'fr-FR',
  'es-ES',
  'hu-HU',
  'pl-PL',
  'de-DE',
  'it-IT',
  'hr-HR',
  'el-GR',
];

// uses CommonJS module syntax to be able use it with Node and webpack as well
module.exports = locales;
