import classNames from 'classnames';
import { Link } from 'components/routing';
import { MouseEventHandler, ReactNode } from 'react';
import styles from './Button.module.scss';

export type ButtonCommonProps = {
  type?: 'button' | 'submit' | 'reset';
  color?: 'default' | 'primary' | 'secondary' | 'tertiary';
  variant?: 'default' | 'link';
  hasBackground?: boolean;
  fullWidth?: boolean;
  noWrap?: boolean;
  children: ReactNode;
  ariaLabel?: string;
  disabled?: boolean;
  ring?: boolean;
  size?: 'tiny' | 'small' | 'normal' | 'large';
  onClick?:
    | MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    | undefined;
  to?: string; // <Link> for internal routing
  toParams?: object;
  href?: string; // <a> for external URL
  external?: boolean; // open in new tab
  disableUppercase?: boolean;
  form?: string;
};

export type ButtonProps = ButtonCommonProps &
  (
    | {
        showAsIcon?: false;
        icon?: ReactNode;
      }
    | { showAsIcon: true; icon?: never }
  );

function Button({
  children,
  type,
  color = 'default',
  variant = 'default',
  hasBackground = true,
  onClick,
  ariaLabel,
  to,
  toParams = {},
  href,
  disabled,
  ring,
  showAsIcon,
  icon,
  external,
  disableUppercase,
  fullWidth,
  noWrap,
  size = 'normal',
  form,
  ...props
}: ButtonProps) {
  const isExternalLinkOrAnchor = (href || to) && external;
  const Component = href ? 'a' : to ? Link : 'button';
  const buttonType = Component === 'button' && !type ? 'button' : type;

  return (
    <Component
      aria-label={ariaLabel}
      type={buttonType}
      disabled={disabled}
      href={href ? href : undefined}
      rel={isExternalLinkOrAnchor ? 'noopener noreferrer' : undefined}
      target={!disabled && isExternalLinkOrAnchor ? '_blank' : undefined}
      to={to || ''}
      params={to ? toParams : undefined}
      onClick={onClick}
      form={Component === 'button' ? form : undefined}
      {...props}
      className={classNames(
        {
          'no-underline': to || href,
          'ring-1 ring-current': ring,
          'bg-blue-500 hover:bg-blue-600 text-white hover:text-white !ring-blue-400':
            color === 'primary' && !icon,
          'bg-blue-100 hover:bg-blue-200 text-blue-700 hover:text-blue-800 !ring-blue-400':
            color === 'primary' && icon,
          'bg-green-500 hover:bg-green-600 text-white hover:text-white !ring-green-400':
            color === 'secondary' && !icon,
          'bg-green-50 hover:bg-green-100 text-green-700 hover:text-green-800 !ring-green-400':
            color === 'secondary' && icon,
          'bg-white hover:bg-gray-100 text-gray-700 hover:text-gray-800 !ring-gray-300':
            color === 'tertiary' && !icon,
          'bg-gray-200 hover:bg-gray-300 text-gray-700 hover:text-gray-800 !ring-gray-300':
            color === 'tertiary' && icon,
          'bg-none text-gray-500': variant === 'link',
          'hover:bg-gray-50': variant === 'link',
          'hover:bg-gray-100': showAsIcon && color === 'default',
          'hover:bg-gray-200': !hasBackground,
          'pointer-events-none': disabled,
          [styles.showAsIcon]: showAsIcon,
          'w-full justify-center': fullWidth,
          'whitespace-nowrap': noWrap,
          'p-2': size === 'tiny' && showAsIcon,
          'p-3': size === 'small' && showAsIcon,
          'p-4': size === 'normal' && showAsIcon,
          'p-5': size === 'large' && showAsIcon,
          'py-2 px-3 text-xs': size === 'tiny' && !showAsIcon,
          'py-3 px-4 text-sm': size === 'small' && !showAsIcon,
          'py-4 px-5 text-base': size === 'normal' && !showAsIcon,
          'py-5 px-6 text-lg': size === 'large' && !showAsIcon,
          'disabled:bg-gray-200 disabled:text-gray-900':
            variant === 'default' && disabled,
          'uppercase': !disableUppercase,
        },
        'relative inline-flex grow-0 h-fit transition-colors font-normal leading-none rounded-full disabled:cursor-default disabled:opacity-20'
      )}
    >
      {icon && (
        <>
          <span
            className={classNames(
              {
                'bg-blue-500 text-white': color === 'primary',
                'bg-green-500 border border-green-50 text-white':
                  color === 'secondary',
                'bg-white border border-gray-200 text-gray-500':
                  color === 'tertiary',
                [styles['circle-tiny']]: size === 'tiny',
              },
              'absolute aspect-square flex items-center justify-center rounded-full top-0 left-0 h-full mr-2'
            )}
          >
            {icon}
          </span>
          <span
            className={classNames(
              fullWidth
                ? ''
                : color === 'default'
                ? {
                    'ml-6': size === 'normal' || size === 'large',
                    'ml-[20px]': size === 'small',
                    'ml-5': size === 'tiny',
                  }
                : {
                    'ml-[40px]': size === 'large',
                    'ml-7': size === 'normal',
                    'ml-6': size === 'small',
                    'ml-5': size === 'tiny',
                  }
            )}
          >
            {children}
          </span>
        </>
      )}
      {!icon && children}
    </Component>
  );
}

export default Button;
